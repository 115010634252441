import moment from 'moment-timezone'

/**
 * Gets the timezone from a clock's data-timezone attribute, finds the current time in that timezone,
 * and sets the clock hands accordingly
 */

const SELECTORS = {
    COMPONENT: '.js-clock',
}

export default class Clock {
    /**
     * @desc Set time on clock by getting current time within timezone and rotating hands
     * @param {HTMLElement} el - Container element for clock
     */

    constructor(element) {
        this.element = element
        this.timezone = this.element.dataset.timezone
        this.time = moment().tz(this.timezone)

        this.hourHand = element.querySelector('[data-hand="hourHand"]')
        this.minuteHand = element.querySelector('[data-hand="minuteHand"]')

        this.rotateHands()
    }

    /**
     * @desc Rotate hour and minute hands based on current time in this element's timezone
     * Hour rotation calculation: hours % 12 * 30 + (minutes / 2)
     * Minutes rotation calculation: minutes * 6
     */

    rotateHands() {
        const hour = this.time.hour()
        const minute = this.time.minute()

        this.hourHand.style.transform = `rotate(${(hour % 12) * 30 +
            minute / 2}deg)`
        this.minuteHand.style.transform = `rotate(${minute * 6}deg)`
    }
}

/**
 * @desc Test component definition used in module-loader
 */

export const ClockComponent = {
    name: 'Clock',
    componentClass: SELECTORS.COMPONENT,
    Source: Clock,
}
