export default function onShareClick(website, text, url) {
    if (process.env.NODE_ENV === 'development') {
        console.log({'website': website, 'text': text, 'url': url})
    }

    switch (website) {
        case 'facebook':
            // @todo: sharer.php no longer accepts params so we should update this to use
            // https://www.facebook.com/dialog/share
            window.open('https://www.facebook.com/sharer/sharer.php?u=' + encodeURIComponent(url) + '&t=' + encodeURIComponent(text) + '', '', 'toolbar=0, status=0, width=900, height=500')
            break

        case 'twitter':
            // Twitter URLs are always shortened to 23 characters https://t.co/XXXXXXXXXX. Tweet is 280 max.
            // So text is max length 280 - 1 (space) - 23 (url) - 1 (space) - 14 (@IndexVentures) = 241
            if (text.length > 241) {
                text = text.substring(0, 241 - 3) + '…'
            }
            text += ' ' + url + ' @IndexVentures'
            window.open('https://x.com/intent/post?text=' + encodeURIComponent(text), '', 'toolbar=0, status=0, width=650, height=500')
            break

        case 'linkedIn':
        case 'linkedin':
            window.open('https://www.linkedin.com/cws/share?url=' + encodeURIComponent(url) + '&token=&isFramed=true', 'linkedin', 'toolbar=no,width=550,height=550')
            break

        case 'email':
            location.href = 'mailto:?body=' + encodeURIComponent(text) + '&subject=' + encodeURIComponent(url)
            break
        case 'webshare':
            if (!navigator.canShare) return

            navigator.share({
                text: text,
                url: encodeURIComponent(url),
            })
    }
}
