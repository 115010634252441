export function isMobile() {
    return window.innerWidth <= GLOBAL_CONSTANTS.BREAKPOINTS.MOBILE_LG
}

export function isStandardTablet() {
    return window.innerWidth < GLOBAL_CONSTANTS.BREAKPOINTS.TABLET
}

export function isTablet() {
    return window.innerWidth <= GLOBAL_CONSTANTS.BREAKPOINTS.TABLET_LG
}

export function isDesktop() {
    return window.innerWidth >= GLOBAL_CONSTANTS.BREAKPOINTS.DESKTOP
}

export function isLandscape() {
    return window.innerWidth > window.innerHeight
}

export function isTouchDevice() {
    return !window.matchMedia('(pointer:fine)').matches
}

const TIMING = {
    FAST_ANIM_TIME: 250, //this should be kept in parity with the CSS $anim_duration_fast variable
    STD_ANIM_TIME: 700, //this should be kept in parity with the CSS $anim_duration variable
    NAV_SCROLL_THROTTLE: 10,
    STANDARD_THROTTLE: 150,
    RESIZE_THROTTLE: 100,
    PAGE_TRANSITION: 0.5, //seconds. this should be kept in parity with the CSS $anim_page_transition variable
}

const EXTERNAL_SCRIPTS = {
    YOUTUBE_API_URL: 'https://www.youtube.com/iframe_api',
    VIMEO_API_URL: 'https://player.vimeo.com/api/player.js',
    TWITTER_API_URL: 'https://platform.twitter.com/widgets.js',
    TYPEFORM_API_URL: 'https://embed.typeform.com/next/embed.js',
}

export const GLOBAL_CONSTANTS = {
    EVENTS: {
        SCROLL: 'scroll',
        RESIZE: 'resize',
        ORIENTATION_CHANGE: 'orientation-change',
        FOCUS: 'focus',
        BLUR: 'blur',
        CHANGE: 'change',
        KEYDOWN: 'keydown',
        LAZY_LOADED: 'lazyloaded',
        FORM_SUBMIT: 'form-submit',
        CLOSE_MOBILE_MENU: 'close-mobile-menu',
        PAGE_ONCE: 'page_once',
        PAGE_AFTER_ENTER: 'page_after_enter',
        PAGE_LOADING: 'page_loading',
        PAGE_LEAVE: 'page_leave',
        PAGE_BEFORE_LEAVE: 'page_before_leave',
        LAZY_LOAD_IMAGE_SCAN: 'lazy_load_image_scan',
    },
    CLASSES: {
        ACTIVE: '-active',
        SEARCH_ACTIVE: '-search-active',
        SCROLL_ACTIVE: '-scroll-active',
        MOBILE_NAV_ACTIVE: '-mobile-nav-active',
        LOADED: '-loaded',
        FIXED: '-fixed',
        HOVER: '-hover',
        INACTIVE: '-inactive',
        STATIC: '-static',
        HIDDEN: '-hidden',
        VISIBLE: '-visible',
        TRANSPARENT: '-transparent',
        HEADER_FULL: '-header-full',
        HEADER_COMPACT: '-header-compact',
    },
    BREAKPOINTS: {
        MOBILE: 320,
        MOBILE_LG: 585,
        TABLET: 768,
        TABLET_LG: 1024,
        DESKTOP: 1280,
        DESKTOP_LG: 1440,
    },
    KEYS: {
        ESC: 27,
    },
    TIMING,
    EXTERNAL_SCRIPTS,
}
