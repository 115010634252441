import { GLOBAL_CONSTANTS } from 'utils/constants'

const SELECTORS = {
    COMPONENT: '.js-relationship-list',
    ITEM: '.js-relationship-list-item',
    BUTTON: '.js-relationship-list-button',
    EXPANDED_LIST: '.js-relationship-list-expanded',
}

export default class RelationshipList {
    constructor(element) {
        this.element = element
        this.expandedList = this.element.querySelector(SELECTORS.EXPANDED_LIST)
        this.button = this.element.querySelector(SELECTORS.BUTTON)

        this.handleClick = this.handleClick.bind(this)

        this.init()
    }

    init() {
        if (this.button) {
            this.button.addEventListener('click', this.handleClick)
        }
    }

    handleClick(e) {
        e.preventDefault()

        this.expandedList.classList.toggle(GLOBAL_CONSTANTS.CLASSES.HIDDEN)
        this.button.classList.toggle(GLOBAL_CONSTANTS.CLASSES.ACTIVE)
    }
}

export const RelationshipListComponent = {
    name: 'Relationship List',
    componentClass: SELECTORS.COMPONENT,
    Source: RelationshipList,
}
