let CLASSES = {
    COMPONENT: '.press-modal__sidebar',
    THEME_BUTTONS: '.press-modal__sidebar__related-pages',
    EXPLORE_TEXT: '.press-modal__sidebar__explore',
    SCROLL_WRAPPER: '.press-modal__content',
    FOOTER_BLOCK: '.press-modal__content__footer',
    BODY_BLOCK: '.press-modal__content__body',
}

const BOTTOM_OFFSET = 15 // 45 gap, minus 30 padding
export default class PressSidebarSticky {
    constructor(el) {
        this.themeButtons = el.querySelector(CLASSES.THEME_BUTTONS)
        this.exploreText = el.querySelector(CLASSES.EXPLORE_TEXT)
        this.footerBlock = document.querySelector(CLASSES.FOOTER_BLOCK)
        this.bodyBlock = document.querySelector(CLASSES.BODY_BLOCK)

        this.scrollWrapper = document.querySelector(CLASSES.SCROLL_WRAPPER)
        this.scrollWrapper.addEventListener('scroll', this.onScrollUpdate.bind(this))
        window.addEventListener('resize', this.onScrollUpdate.bind(this))
        this.onScrollUpdate()
    }

    onScrollUpdate() {
        window.requestAnimationFrame(() => {
            const tbc = this.themeButtons.getBoundingClientRect()
            const fbc = this.footerBlock.getBoundingClientRect()
            const sbc = this.scrollWrapper.getBoundingClientRect()
            const bbc = this.bodyBlock.getBoundingClientRect()

            if (bbc.height < window.innerHeight) {
                this.footerBlock.style.top = 'unset'
                this.footerBlock.style.bottom = '0px'
                // Need to refresh this after potentially altering the footer block styling above.
                this.onScrollUpdate()
            }
            else {
                this.footerBlock.classList.remove('with-transition')
                this.footerBlock.style.top = (bbc.bottom - fbc.height) + 'px'
                this.footerBlock.style.bottom = 'unset'
            }

            // Set the explorer text so that it aligns with the top of the footer block
            let explorerTop = fbc.top

            // If the explorer text is above the back button (90px) plus the height of the theme buttons, ensure it is below.
            if (explorerTop < (90 + tbc.height)) {
                explorerTop = 90 + tbc.height
            }
            this.exploreText.style.top = explorerTop + 'px'

            // Default the theme buttons to the bottom of the scroll wrapper (with an offset)
            let themeButtonTop = sbc.bottom - this.scrollWrapper.scrollTop - BOTTOM_OFFSET - tbc.height

            // If the theme buttons are above the back button, fix them below it
            if (themeButtonTop < 90) {
                themeButtonTop = 90
            }
            // If the theme buttons are below the explorer text, fix them above it
            else if (themeButtonTop + tbc.height > explorerTop) {
                themeButtonTop = explorerTop - tbc.height
            }

            this.themeButtons.style.top = themeButtonTop + 'px'
            this.themeButtons.style.bottom = 'unset'
        })
    }
}

export const PressSidebarStickyComponent = {
    name: 'PressSidebarSticky',
    componentClass: CLASSES.COMPONENT,
    Source: PressSidebarSticky,
}