export let CLASSES = {
    THEME_CHOOSER_BLOCK: '.js-press-theme-chooser',
    LABEL: '.js-press-theme-chooser-label',
    ICONS_CONTAINER: '.js-press-theme-side-icons-container',
    ICON_ARROW: '.js-press-theme-arrow-down',
}

export default class PressThemeChooser {
    constructor(el) {
        this.themeChooser = el
        this.themeChooserMarginBottom = this.themeChooser.style.marginBottom

        this.label = this.themeChooser.querySelector(CLASSES.LABEL)
        this.footer = document.querySelector('footer.footer-container')

        this.iconsContainer = document.querySelector(CLASSES.ICONS_CONTAINER)
        this.iconArrow = document.querySelector(CLASSES.ICON_ARROW)
        this.iconsContainerMarginBottom = this.iconsContainer.style.marginBottom

        this.handleLabelClick = this.handleLabelClick.bind(this)
        this.stickToFooter = this.stickToFooter.bind(this)
        this.init = this.init.bind(this)

        this.init()
    }

    init() {
        this.label.addEventListener('click', this.handleLabelClick)
        window.addEventListener('scroll', this.stickToFooter)
    }

    stickToFooter() {
        let rect = this.footer.getBoundingClientRect()
        if (rect.top <= window.innerHeight) {
            let footerHeight = this.footer.offsetHeight
            if (this.themeChooser.style.position === 'fixed') {
                this.themeChooser.style.position = 'absolute'
                this.themeChooser.style.bottom = `${footerHeight}px`
            }
            if (this.iconsContainer.style.position === 'fixed') {
                this.iconsContainer.style.position = 'absolute'
                this.iconArrow.style.transform = 'rotate(180deg)'
                this.iconsContainer.style.bottom = 0
            }
        } else {
            if (this.themeChooser.style.position === 'absolute') {
                this.themeChooser.style.position = 'fixed'
                this.themeChooser.style.bottom = 0
                this.themeChooser.style.marginBottom = this.themeChooserMarginBottom
            }
            if (this.iconsContainer.style.position === 'absolute') {
                this.iconsContainer.style.position = 'fixed'
                this.iconArrow.style.transform = 'rotate(0deg)'
                this.iconsContainer.style.bottom = 0
                this.iconsContainer.style.marginBottom = this.iconsContainerMarginBottom
            }
        }
    }

    handleLabelClick = () => {
        this.themeChooser.classList.toggle('press-theme-chooser--open')
    }

    tearDown() {
        this.label.removeEventListener('click', this.handleLabelClick)
        window.removeEventListener('scroll', this.stickToFooter)
    }
}

export const PressThemeChooserComponent = {
    name: 'PressThemeChooser',
    componentClass: CLASSES.THEME_CHOOSER_BLOCK,
    Source: PressThemeChooser,
}

