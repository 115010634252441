const CLASSES = {
    COMPONENT: '#perspectives_sections',
}

export default class FilterPanel {
    constructor(element) {
        this.target = element
        this.observer = this.registerObserver()
        this.observer.observe(this.target)
        this.filterPanel = document.querySelector('#filter-panel')
    }

    /**
     * @desc Creates an observer in which we can register our elements against
     * @param {HTMLElement} el - Image element
     */
    registerObserver() {
        const options = {
            root: null,
            rootMargin: '0px 0px -100% 0px',
            threshold: 0.0,
        }

        return new IntersectionObserver(this.filterPanelIsStuck.bind(this), options)
    }

    /**
     * @desc Callback for intersectionObserver that says the section is in the viewport
     * and is ready to be displayed.
     * @param {HTMLElement} entries - List of image entries from the observer
     */
    filterPanelIsStuck(entries) {
        entries.forEach((entry) => {
            if (entry.isIntersecting) {
                this.filterPanel.classList.add('is-stuck')
            }
            else {
                this.filterPanel.classList.remove('is-stuck')
            }
        })
    }

}

export const FilterPanelComponent = {
    name: 'FilterPanel',
    componentClass: CLASSES.COMPONENT,
    Source: FilterPanel,
}
