let CLASSES = {
    COMPONENT: '.js-press-slider',
    CAROUSEL: '.js-press-slider-carousel',
    SLIDE: '.js-press-slider-slide',
    NAV_ITEM: '.js-press-slider-nav-item',
    SCROLLBAR: '.js-press-slider-scrollbar',
    SCROLLBAR_RECT: '.js-press-slider-scrollbar rect',
}

export default class PressSlider {
    constructor(el) {
        this.pressSlider = el
        this.pressSliderCarousel = el.querySelectorAll(CLASSES.CAROUSEL)[0]
        this.allSlides = el.querySelectorAll(CLASSES.SLIDE)
        this.allNavItems = el.querySelectorAll(CLASSES.NAV_ITEM)
        this.registerObserver = this.registerObserver.bind(this)

        this.allSlides.forEach(element => {
            element.observer = this.registerObserver()
            element.observer.observe(element)
        })

        this.onMouseDown = this.onMouseDown.bind(this)
        this.onMouseMove = this.onMouseMove.bind(this)
        this.onMouseLeave = this.onMouseLeave.bind(this)
        this.onMouseUp = this.onMouseUp.bind(this)
        this.reEnableScrollSnap = this.reEnableScrollSnap.bind(this)
        this.pressSliderCarousel.removeEventListener('mousedown', this.onMouseDown)
        this.pressSliderCarousel.addEventListener('mousedown', this.onMouseDown)
        this.pressSliderCarousel.removeEventListener('mouseleave', this.onMouseLeave)
        this.pressSliderCarousel.addEventListener('mouseleave', this.onMouseLeave)
        document.body.removeEventListener('mouseup', this.onMouseUp)
        document.body.addEventListener('mouseup', this.onMouseUp)

        this.isDragging = false
        this.grabX = 0
        this.activeDelta = 0
        this.resetTimeoutId = 0

        // Scrollbar
        this.scrollbar = el.querySelector(CLASSES.SCROLLBAR)
        this.scrollbarRect = el.querySelector(CLASSES.SCROLLBAR_RECT)
        this.updateScrollbar = this.updateScrollbar.bind(this)
        if (this.scrollbar) {
            this.pressSliderCarousel.removeEventListener('scroll', this.updateScrollbar)
            this.pressSliderCarousel.addEventListener('scroll', this.updateScrollbar)
            this.updateScrollbar()
        }
    }

    registerObserver() {
        const options = {
            root: this.pressSliderCarousel,
            rootMargin: '0px 0px 0px 0px',
            threshold: [0.55],
        }

        // The slides aren't full width so we need to adjust the options
        if (this.pressSliderCarousel.classList.contains('highlights-multi-image-block__carousel')) {
            options.threshold = [0.8]
        }

        return new IntersectionObserver(this.updateMobileNav.bind(this), options)
    }

    updateMobileNav(event) {
        if (event[0].isIntersecting) {
            let slide = event[0].target.closest(CLASSES.SLIDE)
            let delta = parseInt(slide.dataset['delta'])
            this.activeDelta = delta
            this.allNavItems.forEach(item => {
                if (delta === parseInt(item.dataset['delta'])) {
                    item.classList.add('is-active')
                } else {
                    item.classList.remove('is-active')
                }
            })
        }
    }

    onMouseDown(event) {
        this.isDragging = true
        this.pressSliderCarousel.style.cursor = 'grabbing'
        this.pressSliderCarousel.style.userSelect = 'none'
        this.pressSliderCarousel.removeEventListener('mousemove', this.onMouseMove)
        this.pressSliderCarousel.addEventListener('mousemove', this.onMouseMove)
        this.pressSliderCarousel.classList.remove('scroll-snap')
        this.grabX = event.offsetX
        this.scrollX = this.pressSliderCarousel.scrollLeft
        clearTimeout(this.resetTimeoutId)
    }

    onMouseMove(event) {
        if (this.isDragging) {
            this.pressSliderCarousel.scrollLeft = this.scrollX + this.grabX - event.offsetX
        }
    }

    onMouseLeave() {
        this.onMouseUp()
    }

    onMouseUp() {
        if (this.isDragging) {
            this.pressSliderCarousel.style.cursor = 'initial'
            this.pressSliderCarousel.style.userSelect = 'initial'
            this.isDragging = false
            this.pressSliderCarousel.removeEventListener('mousemove', this.onMouseMove)
            // Just re-enabling scroll snap doesn't smooth scroll so we have to do it manually
            let activeSlide = this.pressSliderCarousel.querySelector(`[data-delta='${this.activeDelta}']`)
            let scrollToLeft = activeSlide.offsetLeft
            // if the carousel is wider than the slides we need to adjust for it
            scrollToLeft -= (this.pressSliderCarousel.offsetWidth - activeSlide.offsetWidth) / 2
            this.pressSliderCarousel.scrollTo({
                left: scrollToLeft,
                behavior: 'smooth',
            })
            // Doing this on scrollend causes jumping bugs but works as a timeout
            clearTimeout(this.resetTimeoutId)
            this.resetTimeoutId = window.setTimeout(this.reEnableScrollSnap, 300)
        }
    }

    reEnableScrollSnap() {
        if (!this.isDragging) {
            this.pressSliderCarousel.classList.add('scroll-snap')
        }
    }

    updateScrollbar() {
        let sliderMargin = 20
        let sliderWidth = this.pressSlider.offsetWidth - (2 * sliderMargin)
        let carouselWidth = this.pressSliderCarousel.scrollWidth
        let rectWidth = (sliderWidth / carouselWidth * sliderWidth) + sliderMargin
        let carouselX = this.pressSliderCarousel.scrollLeft
        let rectX = carouselX / carouselWidth * sliderWidth
        this.scrollbar.setAttribute('style', `margin: 0 ${sliderMargin}px;`)
        this.scrollbar.setAttribute('width', `${sliderWidth}`)
        this.scrollbar.setAttribute('viewBox', `0 0 ${sliderWidth} 1`)
        this.scrollbarRect.setAttribute('x', `${rectX}`)
        this.scrollbarRect.setAttribute('width', `${rectWidth}`)
    }

    tearDown() {
        this.pressSliderCarousel.removeEventListener('mousedown', this.onMouseDown)
        this.pressSliderCarousel.removeEventListener('mousemove', this.onMouseMove)
        this.pressSliderCarousel.removeEventListener('mouseleave', this.onMouseLeave)
        document.body.removeEventListener('mouseup', this.onMouseUp)
        clearTimeout(this.resetTimeoutId)
        this.pressSliderCarousel.removeEventListener('scroll', this.updateScrollbar)
    }
}

export const PressSliderComponent = {
    name: 'PressSlider',
    componentClass: CLASSES.COMPONENT,
    Source: PressSlider,
}