import { GLOBAL_CONSTANTS } from 'utils/constants'
import Emitter from 'utils/emitter'

const CLASSES = {
    COMPONENT: '.perspectives-all-posts',
    SEARCH_BUTTON: '#perspectives-search__button',
    SEARCH_INPUT: '#perspectives-search__input',
    PAGER_LINKS: '.pagination a',
    RESULT_LIST: '.perspectives-all-posts__results',
    IS_SEARCHING: 'is-searching',
}

export default class CategorySearch {
    constructor(el) {
        this.allPosts = el
        this.searchButton = this.allPosts.querySelector(CLASSES.SEARCH_BUTTON)
        this.searchInput = this.allPosts.querySelector(CLASSES.SEARCH_INPUT)
        this.resultList = el.querySelector(CLASSES.RESULT_LIST)

        if (this.allPosts) {
            if (this.searchButton) {
                this.searchButton.addEventListener('click', this.onSearchClick)
            }

        }

        this.processPagerLinks()
    }

    processPagerLinks() {
        this.allPosts.querySelectorAll(CLASSES.PAGER_LINKS).forEach(link => {
            link.addEventListener('click', this.onPagerLinkClick)
        })
    }

    onSearchClick = (event) => {
        event.preventDefault()
        if (process.env.NODE_ENV === 'development') {
            console.groupCollapsed('CategorySearch OnSearch Debug')
            console.log('Event', event)
            console.log('Search Input', this.searchInput.value)
            console.trace()
            console.groupEnd()
        }

        let path = `${window.location.pathname}?query=${encodeURIComponent(this.searchInput.value)}`
        this.executeSearch(path)
    }

    onPagerLinkClick = (event) => {
        event.preventDefault()
        event.stopPropagation()
        let path = event.target.closest('a').href
        if (path) {
            this.executeSearch(path)
        }
        else {
            console.warn('PAGER EVENT - NO PATH', event)
        }
    }

    executeSearch(path) {
        window.scrollTo({
            top: this.allPosts.offsetTop,
            behavior: 'smooth',
        })

        // Set the new path straight away so if the user needs to refresh they get teh correct page.
        history.pushState({}, document.querySelector('title'), path)

        fetch(path)
            .then(data => {
                return data.text()
            })
            .then(html => {
                let parser = new DOMParser()
                let doc = parser.parseFromString(html, 'text/html')
                this.resultList.innerHTML = doc.querySelector(CLASSES.RESULT_LIST).innerHTML

                // I don't think the title changes but if it does we replace the state
                history.replaceState({}, doc.querySelector('title'), path)

                // @TODO - May be able to replace all of this using a Barba View/Namespace...
                // Ensure the new result list has the LazyLoad events bound to it.
                Emitter.emit(GLOBAL_CONSTANTS.EVENTS.LAZY_LOAD_IMAGE_SCAN, this.resultList)

                this.processPagerLinks()

            }).catch(function (err) {
                console.warn('Fetch failed', err)
                window.location = path
            })
    }
}

export const CategorySearchComponent = {
    name: 'CategorySearch',
    componentClass: CLASSES.COMPONENT,
    Source: CategorySearch,
}
