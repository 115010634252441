import * as Sentry from '@sentry/browser'

const CLASSES = {
    COMPONENT: '.swipe-scroll-list',
    PAGERS: '.carousel__pager',
    PAGER_ITEMS: '.carousel__pager li',
}

export default class ScrollList {
    /**
     * @param {HTMLElement} el
     */
    constructor(el) {
        const that = this
        this.scrollList = el

        this.scrollPlaylist = this.scrollList.closest('.playlist')

        if (this.scrollPlaylist) {
            this.scrollListPagers = this.scrollPlaylist.querySelectorAll(CLASSES.PAGERS)
            if (this.scrollListPagers.length === 0) {
                this.scrollListPagers = undefined
            }
            else {
                this.scrollListPagerItems = this.scrollPlaylist.querySelectorAll(CLASSES.PAGER_ITEMS)

                // Pager dots click
                this.scrollListPagerItems.forEach(pagerItem => {
                    pagerItem.addEventListener('click', function() {
                        let targetIndex = parseInt(this.dataset.index)
                        that.scrollToListItem(targetIndex)
                    })
                })
            }
        }
        this.scrollListItems = this.scrollList.querySelectorAll('.carousel__list-item')

        const parent = this.scrollList.parentNode

        this.nextButton = parent.querySelector('.scroll-list-button-next')
        this.prevButton = parent.querySelector('.scroll-list-button-previous')

        if (this.nextButton && this.prevButton) {
            this.setCarouselButtonContainerHeight()
            this.registerNextPrevButtonEvents()
        } else {
            Sentry.captureException(new Error('Missing next or previous button when calculating ScrollList height'))
        }

        this.registerEventHandlers()

        // Trigger it on init
        this.updateScrollOffsets()
    }

    registerEventHandlers() {
        // Listen for scroll and update the left/right scroll distance data attributes
        this.scrollList.addEventListener('scroll', () => {
            this.updateScrollOffsets()
        }, { passive: true })

        // Listen for resize (as this will also alter the left/right scroll distances.
        window.addEventListener('resize', () => {
            this.updateScrollOffsets()
            this.setCarouselButtonContainerHeight()
        }, { passive: true })
    }

    registerNextPrevButtonEvents() {
        this.nextButton.addEventListener('click', (e) => {
            // Todo we should use the same scrollTo code that I just used on the pager buttons
            this.onPrevNextClick('next')
            e.stopPropagation()
            e.preventDefault()
        })

        this.prevButton.addEventListener('click', (e) => {
            this.onPrevNextClick('prev')
            e.stopPropagation()
            e.preventDefault()
        })
    }

    setCarouselButtonContainerHeight() {
        const image = this.scrollList.querySelector('.carousel__image-wrapper')

        const icons = [this.prevButton, this.nextButton]
            .map(button => button.querySelector('.scroll-list-button__icon'))

        if (!image || !icons.every(Boolean)) {
            Sentry.captureException(new Error('ScrollList: Missing image or icon when calculating height'))
            return
        }

        icons.forEach(icon => icon.style.height = `${image.clientHeight}px`)
    }


    updateScrollOffsets() {
        this.scrollList.dataset.scrollLeft = this.scrollList.scrollLeft
        this.scrollList.dataset.scrollRight = this.scrollList.scrollWidth - this.scrollList.scrollLeft - this.scrollList.offsetWidth

        if (this.scrollList.dataset.scrollLeft < 1) {
            this.scrollList.dataset.scrollLeft = 0
        }
        // The right arrow wasn't disappearing because it was 0.5 instead of 0, so changed to < 1
        if (this.scrollList.dataset.scrollRight < 1) {
            this.scrollList.dataset.scrollRight = 0
        }

        // Update active pager
        if (this.scrollListItems.length) {
            let firstItemStyle = this.scrollListItems[0].currentStyle || window.getComputedStyle(this.scrollListItems[0])
            let itemWidth = parseFloat(firstItemStyle.width) + parseFloat(firstItemStyle.marginRight)
            let left = parseFloat(this.scrollList.scrollLeft)
            let targetIndex = Math.round(left / itemWidth)
            let lastPager = this.scrollListPagerItems[this.scrollListPagerItems.length - 1]
            let lastPagerIndex = lastPager.dataset.index
            // It doesn't always activate the last dot because it doesn't quite reach that 'page'
            if (targetIndex === lastPagerIndex - 1) {
                targetIndex = lastPagerIndex
            }
            if (this.scrollListPagers) {
                this.scrollListPagers.forEach(pager => {
                    let target = pager.querySelectorAll(`[data-index='${targetIndex}']`)
                    if (target.length) {
                        pager.querySelectorAll('li').forEach(e => e.classList.remove('is-active'))
                        target[0].classList.add('is-active')
                    }
                })
            }
        }
    }

    updateScrollListPagination(delta) {
        const scrollListItem = this.scrollList.querySelector('.scroll-list-item')
        const style = scrollListItem.currentStyle || window.getComputedStyle(scrollListItem)
        let offset = delta * (scrollListItem.offsetWidth + parseInt(style.marginRight))
        this.scrollList.scrollBy({ left: offset, behavior: 'smooth' })
    }

    onPrevNextClick(prevOrNext) {
        // There are 2 separate pagers for tablet and desktop which used to have different numbers of items per page.
        if (this.scrollListPagers && window.innerWidth >= 768) {
            this.scrollListPagers.forEach(pager => {
                let pagerStyle = pager.currentStyle || window.getComputedStyle(pager)
                if (pagerStyle.display !== 'none') {
                    let activePagerItem = pager.querySelector('.is-active')
                    if (prevOrNext === 'next') {
                        let targetPagerItem = activePagerItem.nextElementSibling || activePagerItem
                        let targetIndex = targetPagerItem.dataset.index
                        this.scrollToListItem(targetIndex)
                    }
                    if (prevOrNext === 'prev') {
                        let targetPagerItem = activePagerItem.previousElementSibling || activePagerItem
                        let targetIndex = targetPagerItem.dataset.index
                        this.scrollToListItem(targetIndex)
                    }
                }
            })
        }
        else {
            let deltaConst = 1
            if (window.innerWidth >= 768) {
                deltaConst = 2
            }
            this.updateScrollListPagination(deltaConst * (prevOrNext === 'next' ? 1 : -1))
        }
    }

    scrollToListItem(targetIndex) {
        let targetListItem = this.scrollList.querySelector(`[data-index='${targetIndex}']`)
        let firstItemStyle = this.scrollListItems[0].currentStyle || window.getComputedStyle(this.scrollListItems[0])
        let gotoLeft = parseFloat(targetListItem.offsetLeft) - parseFloat(firstItemStyle.marginLeft)
        this.scrollList.scrollTo({ left: gotoLeft, behavior: 'smooth' })
    }
}

export const ScrollListComponent = {
    name: 'ScrollList',
    componentClass: CLASSES.COMPONENT,
    Source: ScrollList,
}
