import { GLOBAL_CONSTANTS } from 'utils/constants'
import chunkArray from 'utils/chunkArray'

const STRINGS = {
    ALL: 'all',
    ROLE_SLUG: 'role',
    DEFAULT_ROLE: 'investor',
    STRATEGIST_ROLE: 'strategist',
    OPERATOR_ROLE: 'operator',
}

const SELECTORS = {
    COMPONENT: '.js-team-filters',
    ROLE: '.js-team-filters-role',
    TEAM_MEMBER: '.js-team-member',
    FILTER_FORM: '.js-team-member-form',
    FORM_SELECTS: '.js-team-member-select',
    SECTOR_INPUT_WRAPPER: '.js-team-member-form-sector-wrapper',
}

export default class TeamFilters {
    /**
     * @desc Populates drop-down filters with options and filters page content accordingly
     * @param {HTMLElement} filtersElement - Element for the filters container
     */

    constructor(filtersElement) {
        this.element = filtersElement

        this.filterForm = this.element.querySelector(SELECTORS.FILTER_FORM)
        this.formSelects = this.element.querySelectorAll(SELECTORS.FORM_SELECTS)
        this.sectorInputWrapper = this.element.querySelector(
            SELECTORS.SECTOR_INPUT_WRAPPER,
        )
        this.teamMembers = Array.from(
            this.element.querySelectorAll(SELECTORS.TEAM_MEMBER),
        )

        this.updateFilters = this.updateFilters.bind(this)
        this.setHistoryState = this.setHistoryState.bind(this)
        this.onRoleUpdate = this.onRoleUpdate.bind(this)

        this.roleAnchors = this.generateRoleAnchorMapping()
        this.memberMapping = this.generateMemberFilterMapping()
        this.filters = this.setFilterState()

        this.initialize()
    }

    /**
     * @desc initialize the class functions after global variables are defined
     */
    initialize() {
        this.registerListeners()
        this.filterResults()
    }

    registerListeners() {
        Object.keys(this.roleAnchors).forEach((key) => {
            const instance = this.roleAnchors[key]
            instance.el.addEventListener('click', this.onRoleUpdate)
        })

        this.filterForm.addEventListener('submit', this.updateFilters)

        window.onpopstate = this.setHistoryState
        window.onpushstate = this.setHistoryState
    }

    setHistoryState() {
        this.filters = this.setFilterState()
        this.filterResults()
    }

    generateMemberFilterMapping() {
        return this.teamMembers.map((member) => {
            const location = member.dataset.location
            const role = member.dataset.role
            const sectors = JSON.parse(member.dataset.sectors)

            return {
                location,
                role,
                sectors,
                filterList: [location, ...sectors],
                el: member,
            }
        })
    }

    generateRoleAnchorMapping() {
        const elements = this.element.querySelectorAll(SELECTORS.ROLE)
        const objectMap = {}
        elements.forEach((el) => {
            const data = {
                el,
                target: el.dataset.target,
            }

            objectMap[el.dataset.target] = data

            if (el.classList.contains(GLOBAL_CONSTANTS.CLASSES.ACTIVE)) {
                this.activeRole = data
            }
        })
        return objectMap
    }

    resetSelects() {
        this.formSelects.forEach((el) => {
            el.selectedIndex = 0
        })
    }

    /**
     * Dynamically set our filter state off of queried selects
     */
    setFilterState() {
        const filters = {}
        const filterMap = new Map()

        const url = new URL(document.location)

        /**
         * URL = /route/param/value/param/value
         * Splitting the above generates ["", route, param, value, param, value]
         * Slicing at 2, -1 starts us after the route and ends at the end of the array
         */
        const params = url.pathname.split('/').slice(2, -1)
        const stateMapping = [...chunkArray(params, 2)]

        stateMapping.forEach((filter) => {
            filterMap.set(filter[0], filter[1])
        })

        /**
         * This order is important to maintain as it needs to match the backend route
         */
        filters[STRINGS.ROLE_SLUG] =
            filterMap.get(STRINGS.ROLE_SLUG) || STRINGS.DEFAULT_ROLE
        this.formSelects.forEach((select) => {
            filters[select.name] = filterMap.get(select.name) || STRINGS.ALL
        })

        return filters
    }

    /**
     * Update our query params on filtering and navigation
     */
    updateQueryString() {
        const url = new URL(document.location)
        const routes = [url.pathname.split('/')[1]]

        Object.keys(this.filters).forEach((key) => {
            routes.push(key, this.filters[key])
        })

        url.pathname = routes.join('/')
        window.history.pushState(null, '', url.toString())
    }

    /**
     * Update our filter state on form submit.
     */
    updateFilters(e) {
        if (e) {
            e.preventDefault()
        }
        const formData = new FormData(this.filterForm)

        const filterObj = {}
        for (const field of formData.entries()) {
            // eslint-disable-line no-undef
            filterObj[field[0]] = field[1]
        }

        this.filters = Object.assign(this.filters, filterObj)

        this.filterResults()
        this.updateQueryString()
    }

    onRoleUpdate(e) {
        e.preventDefault()

        this.resetSelects()

        this.filters = Object.assign(this.filters, {
            role: event.target.dataset.target,
        })

        if ([STRINGS.STRATEGIST_ROLE, STRINGS.OPERATOR_ROLE].includes(event.target.dataset.target)) {
            this.sectorInputWrapper.classList.add(
                GLOBAL_CONSTANTS.CLASSES.HIDDEN,
            )
        } else {
            this.sectorInputWrapper.classList.remove(
                GLOBAL_CONSTANTS.CLASSES.HIDDEN,
            )
        }

        this.updateFilters()
    }

    filterResults() {
        /* eslint-disable */
        this.memberMapping.forEach((member) => {
            const activeMatches = Object.keys(this.filters).reduce(
                (result, key) => {
                    // const filter = this.filters[key]
                    //
                    // if (key !== STRINGS.ROLE_SLUG) {
                    //     result.push(
                    //         filter === STRINGS.ALL ||
                    //             member.filterList.includes(filter),
                    //     )
                    // }
                    return result
                },
                [],
            )
            /* eslint-enable */

            if (member.role === this.filters.role) {
                member.el.classList.remove(GLOBAL_CONSTANTS.CLASSES.HIDDEN)

                if (activeMatches.includes(false)) {
                    member.el.classList.add(GLOBAL_CONSTANTS.CLASSES.INACTIVE)
                    member.el.setAttribute('tabindex', '-1')
                } else {
                    member.el.classList.remove(
                        GLOBAL_CONSTANTS.CLASSES.INACTIVE,
                    )
                    member.el.removeAttribute('tabindex')
                }
            } else {
                member.el.classList.add(GLOBAL_CONSTANTS.CLASSES.HIDDEN)
            }
        })

        if (this.activeRole) {
            this.activeRole.el.classList.remove(GLOBAL_CONSTANTS.CLASSES.ACTIVE)
            this.activeRole.el.removeAttribute('aria-selected', true)
        }
        this.activeRole = this.roleAnchors[this.filters.role]
        this.activeRole.el.classList.add(GLOBAL_CONSTANTS.CLASSES.ACTIVE)
        this.activeRole.el.setAttribute('aria-selected', true)
    }

    /**
     * @desc Tear down the event listeners
     */
    tearDown() {
        this.filterForm.removeEventListener('submit', this.onFormSubmit)
        Object.keys(this.roleAnchors).forEach((key) => {
            const instance = this.roleAnchors[key]
            instance.el.removeEventListener('click', this.onRoleUpdate)
        })
    }
}

/**
 * @desc Component definition for the TeamFilters module
 */

export const TeamFiltersComponent = {
    name: 'Team Filters',
    componentClass: SELECTORS.COMPONENT,
    Source: TeamFilters,
}
