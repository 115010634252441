import barba from '@barba/core'
import { gsap } from 'gsap'
import Emitter from 'utils/emitter'
import { GLOBAL_CONSTANTS } from 'utils/constants'
import { POPUP_CONSTANTS } from 'components/Popup'

const CLASSES = {
    NO_SCROLL: 'js-no-scrolltop',
    NAV_URL: '.js-nav-url',
}

export default class Barba {
    constructor() {
        this.initBarba()
    }

    initBarba() {
        gsap.defaults({
            ease: 'power1.out',
            duration: GLOBAL_CONSTANTS.TIMING.PAGE_TRANSITION,
        })

        const afterLeave = (data, removeContainer) => {
            // Set body classes
            let regexp = /<body.*\sclass=["'](.+?)["'].*>/gi
            let match = regexp.exec(data.next.html)
            if (!match || !match[1]) {
                document.body.setAttribute('class', '')
            } else {
                document.body.setAttribute('class', match[1])
            }

            if (removeContainer) {
                data.current.container.remove()
            }

            const urlOverride = data.next.container.querySelector(
                CLASSES.NAV_URL,
            )

            const url = urlOverride
                ? urlOverride.dataset.navUrl
                : data.next.url.path

            if (data.trigger.classList !== undefined) { // Trigger is an HTML element
                if (!data.trigger.classList.contains(CLASSES.NO_SCROLL)) {
                    window.scrollTo(0, 0)
                    Emitter.emit(GLOBAL_CONSTANTS.EVENTS.PAGE_LEAVE, {
                        url,
                    })
                }
            } else {
                window.scrollTo(0, 0)
                Emitter.emit(GLOBAL_CONSTANTS.EVENTS.PAGE_LEAVE, { url })
            }
        }

        const defaultTransition = {
            name: 'default-transition',
            sync: true,
            once() {
                Emitter.emit(GLOBAL_CONSTANTS.EVENTS.PAGE_ONCE)
                Emitter.emit(GLOBAL_CONSTANTS.EVENTS.PAGE_AFTER_ENTER)
            },
            beforeLeave() {
                Emitter.emit(GLOBAL_CONSTANTS.EVENTS.PAGE_BEFORE_LEAVE)
            },
            leave(data) {
                // Leave animation
                if (
                    data.trigger.classList === undefined ||
                    !data.trigger.classList.contains(CLASSES.NO_SCROLL)) {
                    return gsap.to(
                        data.current.container,
                        {
                            opacity: 0,
                        },
                    )
                }
            },
            afterLeave(data) {
                afterLeave(data, true)
            },
            enter(data) {
                Emitter.emit(GLOBAL_CONSTANTS.EVENTS.PAGE_LOADING, data)

                if (data.next.namespace === 'press_index_page' ) {
                    // Close any open popups on press page
                    Emitter.emit(POPUP_CONSTANTS.EVENTS.CLOSE_POPUP)
                }

                if (
                    data.trigger.classList !== undefined && // Trigger is an HTML element
                    data.trigger.classList.contains(CLASSES.NO_SCROLL)
                ) {
                    gsap.set(data.next.container, { opacity: 1 })
                } else {
                    return gsap.from(
                        data.next.container,
                        {
                            opacity: 0,
                        },
                    )
                }
            },
            afterEnter() {
                Emitter.emit(GLOBAL_CONSTANTS.EVENTS.PAGE_AFTER_ENTER)
            },
        }

        const pressDetailPageEnterTransition = {
            name: 'press-detail-transition',
            sync: true,
            from: {
                namespace: ['press_index_page'],
            },
            to: {
                namespace: ['press_detail_page'],
            },
            once: defaultTransition.once,
            beforeLeave(data) {
                // Close any open popups
                Emitter.emit(POPUP_CONSTANTS.EVENTS.CLOSE_POPUP)
                defaultTransition.beforeLeave(data)
            },
            afterLeave(data) {
                afterLeave(data, true)
            },
            enter(data) {
                console.log('PRESS PAGE TRANSITION ENTER', data)
                Emitter.emit(GLOBAL_CONSTANTS.EVENTS.PAGE_LOADING, data)
                gsap.set(data.next.container, {
                    zIndex: 200,
                })

                // Mobile transitions bottom to top, desktop right to left.
                if (document.documentElement.clientWidth < 1024) {
                    return gsap.fromTo(data.next.container, {
                        y: '100vh',
                    },
                    {
                        y: 0,
                        duration: 0.75,
                        ease: 'power2.inOut',
                    })
                } else {
                    return gsap.from(data.next.container, {
                        x: '100vw',
                        duration: 1.5,
                        ease: 'power2.inOut',
                    })
                }
            },
            afterEnter: defaultTransition.afterEnter,
        }

        const pressDetailPageLeaveTransition = {
            name: 'press-detail-transition',
            sync: true,
            from: {
                namespace: ['press_detail_page'],
            },
            to: {
                namespace: ['press_index_page'],
            },
            once: defaultTransition.once,
            beforeLeave: defaultTransition.beforeLeave,
            leave(data) {
                data.next.container.querySelectorAll('.image-base.grid-thumbnail-static').forEach((image) => {
                    image.classList.add('-loaded')
                })
                gsap.set(data.current.container, {
                    zIndex: 200,
                })
                // Mobile transitions bottom to top, desktop right to left.
                if (document.documentElement.clientWidth < 1024) {
                    return gsap.fromTo(data.current.container, {
                        y: 0,
                    },
                    {
                        y: '100vh',
                        duration: 1.5,
                        ease: 'power2.inOut',
                    })
                }
                else {
                    return gsap.to(data.current.container, {
                        x: '100vw',
                        duration: 1.5,
                        ease: 'power2.inOut',
                    })
                }
            },
            afterLeave(data) {
                afterLeave(data, true)
            },
            enter(data) {
                Emitter.emit(GLOBAL_CONSTANTS.EVENTS.PAGE_LOADING, data)
            },
            afterEnter: defaultTransition.afterEnter,
        }

        barba.hooks.afterEnter(() => {
            if (window.gtag === undefined) {
                return
            }
            if (document.querySelectorAll('.is-handbook').length > 0) {
                return
            }
            let element = document.querySelector('[data-content-group-name]')
            let ga4config = {}
            if (element && element.dataset.contentGroupName) {
                ga4config['content_group'] = element.dataset.contentGroupName
            }
            window.gtag('event', 'page_view', ga4config)
        })

        barba.init({
            transitions: [defaultTransition, pressDetailPageEnterTransition, pressDetailPageLeaveTransition],
            logLevel: process.env.NODE_ENV === 'development' ? 'debug' : 'off',
            prevent: ({ el , event}) => {
                if (el.classList && el.classList.contains('prevent')) {
                    return true
                }
                if (el.href && el.href.match(/(\/documents\/|\/media\/)/)) {
                    return true
                }
                const preventRegex = /^\/(rewarding-talent|destination-europe|scaling-through-chaos|optionplan|expansionplan|destination-usa|startup-jobs|teamplan)/g
                // Disable for the VueJS Apps and Handbooks
                if (el.pathname) {
                    if (el.pathname.match(preventRegex)) {
                        return true
                    }
                }
                // Also prevent when refreshing any VueJS Apps or Handbooks page
                if (event.view && event.view.location && event.view.location.href) {
                    if (event.view.location.pathname.match(preventRegex)) {
                        return true
                    }
                }
            },
            timeout: 30000,
            requestError: (trigger, action, url, response) => {
                if (process.env.NODE_ENV === 'development') {
                    console.log(trigger, action, url, response)
                }
                /**
                 * Passing false prevent Barba from redirecting the user to the requested URL
                 * this is equivalent to e.preventDefault()
                 */
                return true
            },
        })
    }
}

if (process.env.NODE_ENV === 'development') {
    window.history.pushState = (function(nativePushState) {
        return function() {
            console.groupCollapsed('History Push Debug')
            console.log('PushState Arguments', arguments)
            console.trace()
            console.groupEnd()

            nativePushState.apply(this, arguments) //Continue by calling native history.pushState
        }
    })(window.history.pushState)
}

barba.hooks.afterEnter(async () => {
    for (let userbar of document.getElementsByTagName('wagtail-userbar')) {
        try {
            await userbar.initialiseAxe()
        } catch (error) {
            // This is to swallow an error on page load: on page load
            // initialiseAxe is already running at this point, causing an
            // exception.
        }
    }
})