export default (Class) => {
    if (
        document.readyState === 'complete' ||
        document.readyState === 'loaded'
    ) {
        new Class()
        document.removeEventListener('DOMContentLoaded')
    } else {
        document.addEventListener('DOMContentLoaded', () => {
            new Class()
        })
    }
}
