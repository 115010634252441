import * as Sentry from '@sentry/browser'
import sentryConfig from './sentryConfig'
export default class SentryWrapper {
    constructor() {
        if (!sentryConfig) {return}
        if (sentryConfig.environment === 'local') {return}
        Sentry.init({
            dsn: sentryConfig.dsn,
            integrations: [
                Sentry.browserTracingIntegration(),
            ],
            tracesSampleRate: sentryConfig.traces_sample_rate,
            environment: sentryConfig.environment,
            release: sentryConfig.release,
        })
    }
}
