import FounderCarousel, { CLASSES } from 'utils/FounderCarousel'

CLASSES.COMPONENT = '.js-founder-spotlight'

export default class FounderSpotlight extends FounderCarousel {
    constructor(el) {
        super(el)
        this.disableAnimation()
        super.init()
    }
}

export const FounderSpotlightComponent = {
    name: 'FounderSpotlight',
    componentClass: CLASSES.COMPONENT,
    Source: FounderSpotlight,
}
