import Emitter from 'utils/emitter'
import { GLOBAL_CONSTANTS, isStandardTablet } from 'utils/constants'

const CLASSES = {
    COMPONENT: '.js-multi-image',
    IMAGE: '.js-multi-image-image',
    CAPTION: '.js-multi-image-caption',
    COUNT: '.js-multi-image-current-count',
}

export default class MultiImage {
    constructor(element) {
        this.element = element

        this.images = this.element.querySelectorAll(CLASSES.IMAGE)
        this.countEl = this.element.querySelector(CLASSES.COUNT)

        this.onImageClick = this.onImageClick.bind(this)
        this.onActiveImageClick = this.onActiveImageClick.bind(this)

        this.handleResize = this.handleResize.bind(this)

        this.init()
    }

    state = {
        imageStackInit: false,
        stackLayer: {},
    }

    init() {
        this.registerListeners()
        this.generateStackLayerMap()
        if (this.isImageStack()) {
            this.initImageStack()
        }
    }

    registerListeners() {
        Emitter.on('resize', this.handleResize)
    }

    handleResize() {
        if (!this.isImageStack() && this.state.imageStackInit) {
            this.teardownImageStack()
        } else if (this.isImageStack() && !this.state.imageStackInit) {
            this.initImageStack()
        }
    }

    isImageStack() {
        if (this.element.classList.contains('multi-image-block--small')) {
            // Small multi image always display as a stack.
            return true
        } else {
            // Large multi image only stacked on tablet and larger.
            return !isStandardTablet()
        }
    }

    initImageStack() {
        Object.keys(this.state.stackLayer).forEach((item) => {
            const layer = this.state.stackLayer[item]
            layer.image.addEventListener('click', this.onImageClick)
        })

        this.registerActiveStackLayer(this.state.stackLayer[1])

        this.state.imageStackInit = true
    }

    teardownImageStack() {
        this.unregisterActiveStackLayer()
        Object.keys(this.state.stackLayer).forEach((item) => {
            const layer = this.state.stackLayer[item]
            layer.image.removeEventListener('click', this.onImageClick)
        })

        this.state.imageStackInit = false
    }

    generateStackLayerMap() {
        this.images.forEach((image) => {
            const obj = {
                image,
                caption: this.element.querySelector(
                    `${CLASSES.CAPTION}[data-index="${image.dataset.index}"]`,
                ),
            }

            this.state.stackLayer[image.dataset.index] = obj
        })
    }

    /**
     *
     * Unregister listeners on our active stack layer
     */
    unregisterActiveStackLayer() {
        this.activeInstance.caption.classList.remove(
            GLOBAL_CONSTANTS.CLASSES.ACTIVE,
        )
        this.activeInstance.image.classList.remove(
            GLOBAL_CONSTANTS.CLASSES.ACTIVE,
        )
        this.activeInstance.image.addEventListener('click', this.onImageClick)
        this.activeInstance.image.removeEventListener(
            'click',
            this.onActiveImageClick,
        )
    }

    /**
     *
     * Register our handlers and assign the next active stack layer.
     */
    registerActiveStackLayer(instance) {
        delete this.activeInstance
        this.activeInstance = instance
        this.activeInstance.caption.classList.add(
            GLOBAL_CONSTANTS.CLASSES.ACTIVE,
        )
        this.activeInstance.image.classList.add(GLOBAL_CONSTANTS.CLASSES.ACTIVE)
        this.activeInstance.image.removeEventListener(
            'click',
            this.onImageClick,
        )
        this.activeInstance.image.addEventListener(
            'click',
            this.onActiveImageClick,
        )
    }

    /**
     *
     * Handler for click on hidden images
     */
    onImageClick(event) {
        const layerIndex = event.currentTarget.dataset.index
        const layer = this.state.stackLayer[layerIndex]

        this.countEl.innerHTML = layerIndex

        this.unregisterActiveStackLayer()
        this.registerActiveStackLayer(layer)
    }

    /**
     *
     * Handler for click on active image
     */
    onActiveImageClick(event) {
        const layerIndex = event.target.dataset.index

        const nextIndex =
            layerIndex < Object.keys(this.state.stackLayer).length
                ? parseInt(layerIndex) + 1
                : 1

        const layer = this.state.stackLayer[nextIndex]
        this.countEl.innerHTML = nextIndex

        this.unregisterActiveStackLayer()
        this.registerActiveStackLayer(layer)
    }

    /**
     * @desc Tear down the event listeners
     */
    tearDown() {
        Emitter.off('resize', this.handleResize)
    }
}

export const MultiImageComponent = {
    name: 'MultiImage',
    componentClass: CLASSES.COMPONENT,
    Source: MultiImage,
}
