/*
    global cookieConsent
 */

import WaveSurfer from 'wavesurfer.js'

let CLASSES = {
    COMPONENT: '.js-founder-praise',
    WRAPPER: '.js-founder-praise-wrapper',
    CAROUSEL: '.js-founder-praise-carousel',
    SLIDE: '.js-founder-praise-slide',
    PREV: '.js-founder-praise-prev',
    NEXT: '.js-founder-praise-next',
    MOBILE_NAV_ITEM: '.js-founder-praise-mobile-nav-item',

    AUDIO: '.js-founder-praise-audio',
    AUDIO_PLAY: '.js-founder-praise-audio-play',
    AUDIO_PAUSE: '.js-founder-praise-audio-pause',
    AUDIO_PLAYER: '.js-founder-praise-audio-player',

    VIDEO: '.js-founder-praise-video',
    VIDEO_PLAY: '.js-founder-praise-video-play',
    VIDEO_MODAL: '.js-founder-praise-video-modal',
    VIDEO_MODAL_CLOSE: '.js-founder-praise-video-modal-close',
    VIDEO_PLAYER: '.js-founder-praise-video-player',
}

const wavesurferOptions = {
    'height': 36,
    'splitChannels': false,
    'normalize': true,
    'waveColor': '#666666',
    'progressColor': '#cccccc',
    'cursorColor': '#ffffff',
    'cursorWidth': 3,
    'barWidth': 3,
    'barGap': 3,
    'barRadius': 3,
    'barHeight': 1,
    'barAlign': '',
    'minPxPerSec': 1,
    'fillParent': true,
    'mediaControls': false,
    'autoplay': false,
    'interact': true,
    'dragToSeek': true,
    'hideScrollbar': true,
    'audioRate': 1,
    'autoScroll': true,
    'autoCenter': true,
    'sampleRate': 8000,
}

const MOBILE_MAX_WIDTH = 767

export default class FounderPraise {
    constructor(el) {
        this.founderPraiseCarousel = el.querySelectorAll(CLASSES.CAROUSEL)[0]
        this.carouselWrapper = el.querySelectorAll(CLASSES.WRAPPER)[0]
        this.carouselLength = parseInt(this.founderPraiseCarousel.dataset.length)
        this.allSlides = el.querySelectorAll(CLASSES.SLIDE)
        this.allPrevs = el.querySelectorAll(CLASSES.PREV)
        this.allNexts = el.querySelectorAll(CLASSES.NEXT)
        this.allMobileNavItems = el.querySelectorAll(CLASSES.MOBILE_NAV_ITEM)

        this.allAudios = el.querySelectorAll(CLASSES.AUDIO)
        this.allAudioPlays = el.querySelectorAll(CLASSES.AUDIO_PLAY)
        this.allAudioPauses = el.querySelectorAll(CLASSES.AUDIO_PAUSE)
        this.allAudioPlayers = el.querySelectorAll(CLASSES.AUDIO_PLAYER)

        this.allVideos = el.querySelectorAll(CLASSES.VIDEO)
        this.allVideoPlays = el.querySelectorAll(CLASSES.VIDEO_PLAY)
        this.allVideoModals = el.querySelectorAll(CLASSES.VIDEO_MODAL)
        this.allVideoModalCloses = el.querySelectorAll(CLASSES.VIDEO_MODAL_CLOSE)
        this.allVideoPlayers = el.querySelectorAll(CLASSES.VIDEO_PLAYER)

        this.activeDelta = 0

        this.registerObserver = this.registerObserver.bind(this)
        this.activeSlide = this.activeSlide.bind(this)
        this.onPrev = this.onPrev.bind(this)
        this.onNext = this.onNext.bind(this)
        this.setActive = this.setActive.bind(this)
        this.onAudioPlay = this.onAudioPlay.bind(this)
        this.onAudioPause = this.onAudioPause.bind(this)
        this.onVideoPlay = this.onVideoPlay.bind(this)
        this.onVideoClose = this.onVideoClose.bind(this)
        this.stopEverything = this.stopEverything.bind(this)

        this.allSlides.forEach(element => {
            element.observer = this.registerObserver()
            element.observer.observe(element)
        })
        this.allPrevs.forEach(element => {
            element.removeEventListener('mousedown', this.onPrev)
            element.addEventListener('mousedown', this.onPrev)
        })
        this.allNexts.forEach(element => {
            element.removeEventListener('mousedown', this.onNext)
            element.addEventListener('mousedown', this.onNext)
        })
        this.allAudioPlays.forEach(element => {
            element.removeEventListener('mousedown', this.onAudioPlay)
            element.addEventListener('mousedown', this.onAudioPlay)
        })
        this.allAudioPauses.forEach(element => {
            element.removeEventListener('mousedown', this.onAudioPause)
            element.addEventListener('mousedown', this.onAudioPause)
        })
        this.allAudioPlayers.forEach(element => {
            if (element.dataset['url']) {
                element.wavesurfer = WaveSurfer.create({
                    'container': element,
                    'url': element.dataset['url'],
                    ...wavesurferOptions,
                })
                element.wavesurfer.on('finish', () => {
                    element.wavesurfer.setTime(0)
                    this.onAudioPause()
                })
            }
        })

        this.allVideoPlays.forEach(element => {
            element.removeEventListener('mousedown', this.onVideoPlay)
            element.addEventListener('mousedown', this.onVideoPlay)
        })
        this.allVideoModalCloses.forEach(element => {
            element.removeEventListener('mousedown', this.onVideoClose)
            element.addEventListener('mousedown', this.onVideoClose)
        })
        this.allVideoPlayers.forEach(element => {
            element.removeEventListener('ended', this.onVideoClose)
            element.addEventListener('ended', this.onVideoClose)
        })
    }

    registerObserver() {
        const options = {
            root: this.carouselWrapper,
            rootMargin: '0px 0px 0px 0px',
            threshold: [0.6],
        }

        return new IntersectionObserver(this.updateMobileNav.bind(this), options)
    }

    updateMobileNav(event) {
        if (event[0].isIntersecting) {
            if (window.screen.width <= MOBILE_MAX_WIDTH) {
                let slide = event[0].target.closest(CLASSES.SLIDE)
                let delta = parseInt(slide.dataset['delta'])
                this.allMobileNavItems.forEach(item => {
                    if (delta === parseInt(item.dataset['delta'])) {
                        item.classList.add('is-active')
                    } else {
                        item.classList.remove('is-active')
                    }
                })
                if (delta !== parseInt(this.activeDelta)) {
                    this.stopEverything()
                }
                this.activeDelta = delta
            }
        }
    }

    activeSlide() {
        return this.founderPraiseCarousel.querySelectorAll(`[data-delta='${this.activeDelta}']`)[0]
    }

    onPrev() {
        this.setActive(this.activeDelta - 1)
    }

    onNext() {
        this.setActive(this.activeDelta + 1)
    }

    setActive(delta) {
        this.stopEverything()
        if (parseInt(delta) < 0) {
            delta = parseInt(this.carouselLength) - 1
        }
        if (parseInt(delta) >= this.carouselLength) {
            delta = 0
        }
        this.allSlides.forEach(slide => {
            if (parseInt(delta) === parseInt(slide.dataset['delta'])) {
                slide.classList.add('is-active')
            } else {
                slide.classList.remove('is-active')
            }
        })
        this.activeDelta = delta
    }

    onAudioPlay() {
        let slide = this.activeSlide()
        slide.querySelectorAll(CLASSES.AUDIO)[0].classList.remove('is-paused')
        slide.querySelectorAll(CLASSES.AUDIO)[0].classList.add('is-playing')
        slide.querySelectorAll(CLASSES.AUDIO_PLAYER)[0].wavesurfer.play()
    }

    onAudioPause() {
        let slide = this.activeSlide()
        slide.querySelectorAll(CLASSES.AUDIO)[0].classList.remove('is-playing')
        slide.querySelectorAll(CLASSES.AUDIO)[0].classList.add('is-paused')
        slide.querySelectorAll(CLASSES.AUDIO_PLAYER)[0].wavesurfer.pause()
    }

    onVideoPlay() {
        let slide = this.activeSlide()
        slide.querySelectorAll(CLASSES.VIDEO)[0].classList.remove('is-paused')
        slide.querySelectorAll(CLASSES.VIDEO)[0].classList.add('is-playing')
        slide.querySelectorAll(CLASSES.VIDEO_MODAL)[0].classList.add('is-active')
        let uploadedVideoPlayer = slide.querySelectorAll(`${CLASSES.VIDEO_PLAYER} .block-uploaded_video video`)[0]
        if (uploadedVideoPlayer) {
            uploadedVideoPlayer.play()
        }
        // .youtube-player or .vimeo-player
        if (typeof cookieConsent !== 'undefined' && cookieConsent('media')) {
            let embededPlayer = slide.querySelectorAll('.youtube-player, .vimeo-player')[0]
            if (embededPlayer) {
                let iframe = embededPlayer.parentElement.querySelectorAll('iframe')[0]
                if (iframe) {
                    iframe.contentWindow.postMessage('{"event":"command","func":"playVideo","args":""}', '*')
                } else {
                    embededPlayer.click()
                }
            }
        }
    }

    onVideoClose() {
        let slide = this.activeSlide()
        slide.querySelectorAll(CLASSES.VIDEO)[0].classList.remove('is-playing')
        slide.querySelectorAll(CLASSES.VIDEO)[0].classList.add('is-paused')
        slide.querySelectorAll(CLASSES.VIDEO_MODAL)[0].classList.remove('is-active')
        this.stopEverything()
    }

    stopEverything() {
        this.allAudios.forEach(element => {
            element.classList.remove('is-playing')
            element.classList.add('is-paused')
        })
        this.allAudioPlayers.forEach(element => {
            element.wavesurfer.pause()
        })
        this.allVideos.forEach(element => {
            element.classList.remove('is-playing')
            element.classList.add('is-paused')
        })
        this.allVideoModals.forEach(element => {
            element.classList.remove('is-active')
        })
        this.allVideoPlayers.forEach(element => {
            let uploadedVideoPlayer = element.querySelectorAll(`${CLASSES.VIDEO_PLAYER} .block-uploaded_video video`)[0]
            if (uploadedVideoPlayer) {
                uploadedVideoPlayer.pause()
                uploadedVideoPlayer.currentTime = 0
            }
            let youtubePlayer = element.querySelectorAll(`${CLASSES.VIDEO_PLAYER} .youtube-player`)[0]
            if (youtubePlayer) {
                let iframe = youtubePlayer.parentElement.querySelectorAll('iframe')[0]
                if (iframe) {
                    iframe.contentWindow.postMessage('{"event":"command","func":"stopVideo","args":""}', '*')
                }
            }

            let vimeoPlayer = element.querySelectorAll(`${CLASSES.VIDEO_PLAYER} .vimeo-player`)[0]
            if (vimeoPlayer) {
                let iframe = vimeoPlayer.parentElement.querySelectorAll('iframe')[0]
                if (iframe) {
                    iframe.parentNode.removeChild(iframe)
                    vimeoPlayer.parentElement.querySelector('.play-button').style.display = 'block'
                }
            }
        })
    }

    tearDown() {
        this.stopEverything()
        this.allPrevs.forEach(element => {
            element.removeEventListener('mousedown', this.onPrev)
        })
        this.allNexts.forEach(element => {
            element.removeEventListener('mousedown', this.onNext)
        })
        this.allAudioPlays.forEach(element => {
            element.removeEventListener('mousedown', this.onAudioPlay)
        })
        this.allAudioPauses.forEach(element => {
            element.removeEventListener('mousedown', this.onAudioPause)
        })
        this.allVideoPlays.forEach(element => {
            element.removeEventListener('mousedown', this.onVideoPlay)
        })
        this.allVideoModalCloses.forEach(element => {
            element.removeEventListener('mousedown', this.onVideoClose)
        })
        this.allVideoPlayers.forEach(element => {
            element.removeEventListener('ended', this.onVideoClose)
        })
    }
}

export const FounderPraiseComponent = {
    name: 'FounderPraise',
    componentClass: CLASSES.COMPONENT,
    Source: FounderPraise,
}
