const CLASSES = {
    COMPONENT: '.hero-carousel',
    IMAGE_LINK: '.hero-carousel__image-link',
    TEXT_LINK: '.hero-carousel__text-link',
    YOUTUBE_PLAYER: '.youtube-player',
    VIMEO_PLAYER: '.vimeo-player',
}

export default class HeroCarousel {
    constructor(el) {
        this.heroCarousel = el
        this.allHeroCarousels = document.querySelectorAll(CLASSES.COMPONENT)
        this.allImageLinks = document.querySelectorAll(CLASSES.IMAGE_LINK)
        this.allTextlinks = document.querySelectorAll(CLASSES.TEXT_LINK)
        this.carouselInterval = 0

        if (this.allImageLinks) {
            this.allImageLinks.forEach(imageLink => {
                imageLink.removeEventListener('mouseenter', this.onImageMouseEnter)
                imageLink.addEventListener('mouseenter', this.onImageMouseEnter)
                imageLink.removeEventListener('mouseleave', this.onImageTextMouseLeave)
                imageLink.addEventListener('mouseleave', this.onImageTextMouseLeave)
            })
        }

        if (this.allTextlinks) {
            this.allTextlinks.forEach(textLink => {
                textLink.removeEventListener('mouseenter', this.onTextMouseEnter)
                textLink.addEventListener('mouseenter', this.onTextMouseEnter)
                textLink.removeEventListener('mouseleave', this.onImageTextMouseLeave)
                textLink.addEventListener('mouseleave', this.onImageTextMouseLeave)
            })
            this.startCarousel()
        }
    }

    onImageMouseEnter = (event) => {
        this.stopCarousel()
        event.preventDefault()
        event.stopPropagation()
    }

    onTextMouseEnter = (event) => {
        let text = event.target.closest(CLASSES.TEXT_LINK)
        let isActive = text.classList.contains('is-active')
        let delta = text.dataset.delta

        this.stopCarousel()
        if (!isActive) {
            if (typeof delta !== 'undefined') {
                let image = this.heroCarousel.querySelector(`${CLASSES.IMAGE_LINK}[data-delta="${delta}"]`)
                this.allTextlinks.forEach(t => t.classList.remove('is-active'))
                if (text) {
                    text.classList.add('is-active')
                }
                this.allImageLinks.forEach(i => i.classList.remove('is-active'))
                if (image) {
                    image.classList.add('is-active')
                }
                this.setActive(delta)
            }
        }
        event.preventDefault()
        event.stopPropagation()
    }


    onImageTextMouseLeave = (event) => {
        this.startCarousel()
        event.preventDefault()
        event.stopPropagation()
    }


    startCarousel = () => {
        this.stopCarousel()
        if (!this.carouselInterval) {
            this.carouselInterval = window.setInterval(this.nextSlide, 5000)
        }
    }

    stopCarousel = () => {
        clearInterval(this.carouselInterval)
        this.carouselInterval = 0
    }

    nextSlide = () => {
        let activeText = this.heroCarousel.querySelector(`${CLASSES.TEXT_LINK}.is-active`)
        if (activeText && activeText.dataset) {
            let activeDelta = parseInt(activeText.dataset.delta)
            this.setActive(activeDelta + 1)
        }
    }

    setActive = (delta) => {
        delta = parseInt(delta)
        delta = delta > 2 ? 0 : delta
        this.pauseAllVideos()
        let text = this.heroCarousel.querySelector(`${CLASSES.TEXT_LINK}[data-delta="${delta}"]`)
        let image = this.heroCarousel.querySelector(`${CLASSES.IMAGE_LINK}[data-delta="${delta}"]`)
        this.allTextlinks.forEach(t => t.classList.remove('is-active'))
        this.allImageLinks.forEach(i => i.classList.remove('is-active'))
        if (text) {
            text.classList.add('is-active')
        }
        if (image) {
            image.classList.add('is-active')
        }
    }

    pauseAllVideos = () => {
        if (window.innerWidth >= 768) {
            this.allYoutubePlayers = document.querySelectorAll(CLASSES.YOUTUBE_PLAYER)
            if (this.allYoutubePlayers.length) {
                this.allYoutubePlayers.forEach(youtube => {
                    if (youtube.contentWindow) {
                        youtube.contentWindow.postMessage('{"event":"command","func":"' + 'pauseVideo' + '","args":""}', '*')
                    }
                })
            }
            this.allVimeoPlayers = document.querySelectorAll(CLASSES.VIMEO_PLAYER)
            if (this.allVimeoPlayers.length) {
                this.allVimeoPlayers.forEach(vimeo => {
                    if (vimeo.contentWindow) {
                        vimeo.contentWindow.postMessage('{"method":"pause"}', vimeo.src)
                    }
                })
            }
        }
    }

    tearDown() {
        this.stopCarousel()
        if (this.allTextlinks) {
            this.allTextlinks.forEach(textLink => {
                textLink.removeEventListener('mouseover', this.onTextMouseEnter)
            })
        }
    }
}

export const HeroCarouselComponent = {
    name: 'HeroCarousel',
    componentClass: CLASSES.COMPONENT,
    Source: HeroCarousel,
}
