import Emitter from 'utils/emitter'
import {
    GLOBAL_CONSTANTS,
    isTablet,
} from 'utils/constants'
import { throttle } from 'throttle-debounce'
import {
    disableBodyScroll,
    enableBodyScroll,
    clearAllBodyScrollLocks,
} from 'body-scroll-lock'
import RealTimeSearch from './RealTimeSearch'

/**
 * Adds Active state to Nav after page transitions.
 *
 * Makes the nav show / hide based on
 * scroll position / direction.
 */
const SCROLL_UP_BUFFER = 40

const CLASSES = {
    COMPONENT: '.js-navigation',
    NAV_LINK: '.js-nav-menu-link',
    NAV_MENU: '.js-nav-menu',
    SEARCH_ICON: '.js-search-icon',
    SEARCH_WRAPPER: '.js-search-wrapper',
    SEARCH_SCRIM: '.js-search-scrim',
    SEARCH_INPUT: '.js-search-input',
    SEARCH_FORM: '.js-search-form',
    NAV_LIST: '.js-navigation__list',
    NAV_ITEM: '.js-navigation__item',
    NAV_ANCHOR: '.js-navigation__anchor',
    NAV_DARK_BG: '-nav-dark-bg',
    DARK_HERO: '.js-dark-hero',
    TRANSPARENT_HERO: '.page-hero.-bg-color-transparent',
    SKIP_LINK: ' .js-skip-link',
}

const SEARCH_FORM_URL = '/search-form/'


export default class Nav {
    /**
     * @desc Set up nav with elements and bind events.
     * @param {HTMLElement} el - Element that contains possible sub-navigations
     *
     */
    constructor() {
        this.el = document.querySelector(CLASSES.COMPONENT)
        if (!this.el) {
            return
        }

        this.skipLink = document.querySelector(CLASSES.SKIP_LINK)

        this.navList = this.el.querySelector(CLASSES.NAV_LIST)
        this.navListItems = this.navList.querySelectorAll(CLASSES.NAV_ITEM)
        this.navLinkMap = {}
        this.searchIcon = this.el.querySelector(CLASSES.SEARCH_ICON)
        this.searchWrapper = document.querySelector(CLASSES.SEARCH_WRAPPER)
        this.searchScrim = document.querySelector(CLASSES.SEARCH_SCRIM)
        this.searchForm = document.querySelector(CLASSES.SEARCH_FORM)

        this.throttleScroll = null
        this.scrollPosition = 0
        this.navMenuToggle = this.el.querySelector(CLASSES.NAV_LINK)
        this.navMenu = this.el.querySelector(CLASSES.NAV_MENU)
        this.tabItems = [
            this.navMenuToggle,
            ...Array.from(this.navMenu.getElementsByTagName('A')),
        ]

        this.toggleSearchBar = this.toggleSearchBar.bind(this)
        this.openSearchBar = this.openSearchBar.bind(this)
        this.closeSearchBar = this.closeSearchBar.bind(this)

        this.toggleNavMenu = this.toggleNavMenu.bind(this)
        this.handleResize = this.handleResize.bind(this)
        this.handleMenuKeyDown = this.handleMenuKeyDown.bind(this)

        this.closeNavMenu = this.closeNavMenu.bind(this)
        this.updateNavActiveState = this.updateNavActiveState.bind(this)
        this.updateNavBackground = this.updateNavBackground.bind(this)

        this.onBeforePageLeave = this.onBeforePageLeave.bind(this)

        this.initialize()
        this.handleScroll()
        this.generateNavLinkMap()
        this.updateNavBackground()
    }

    /**
     * @desc initialize the class functions after global variables are defined
     */
    initialize() {
        this.registerEvents()
    }

    /**
     * @desc Listen for page transitions.
     */
    registerEvents() {
        Emitter.on(
            GLOBAL_CONSTANTS.EVENTS.PAGE_LOADING,
            this.updateNavBackground,
        )
        Emitter.on(
            GLOBAL_CONSTANTS.EVENTS.PAGE_AFTER_ENTER,
            this.updateNavBackground,
        )
        Emitter.on(
            GLOBAL_CONSTANTS.EVENTS.PAGE_BEFORE_LEAVE,
            this.onBeforePageLeave,
        )
        Emitter.on(
            GLOBAL_CONSTANTS.EVENTS.PAGE_LEAVE,
            this.updateNavActiveState,
        )

        // Close menu when history changes via forward/back buttons.
        window.onpopstate = () => {
            this.closeNavMenu()
        }

        this.throttleScroll = throttle(
            GLOBAL_CONSTANTS.TIMING.NAV_SCROLL_THROTTLE,
            this.handleScroll.bind(this),
        )
        Emitter.on(GLOBAL_CONSTANTS.EVENTS.SCROLL, this.throttleScroll)
        Emitter.on(GLOBAL_CONSTANTS.EVENTS.RESIZE, this.handleResize)

        this.searchIcon.addEventListener('click', this.toggleSearchBar)
        this.searchScrim.addEventListener('click', this.closeSearchBar)
        this.navMenuToggle.addEventListener('click', this.toggleNavMenu)
    }

    generateNavLinkMap() {
        this.navListItems.forEach((item) => {
            const link = item.dataset.link

            this.navLinkMap[link] = {
                el: item,
                anchor: item.querySelector(CLASSES.NAV_ANCHOR),
                link,
            }
        })
    }

    handleResize() {
        if (
            this.el.classList.contains(
                GLOBAL_CONSTANTS.CLASSES.MOBILE_NAV_ACTIVE,
            ) &&
            !isTablet()
        ) {
            this.closeNavMenu()
            this.closeSearchBar()
        }
    }

    onBeforePageLeave() {
        if (document.documentElement.dataset.whatintent === 'keyboard') {
            this.skipLink.focus()
        }
        this.closeNavMenu()
        this.closeSearchBar()
    }

    updateNavBackground() {
        const darkHero = document.body.querySelector(CLASSES.DARK_HERO) && !document.body.querySelector(CLASSES.TRANSPARENT_HERO)
        const indexPressPage = document.body.classList.contains('press-index')
        if (darkHero || indexPressPage) {
            this.el.classList.add(CLASSES.NAV_DARK_BG)
        } else {
            this.el.classList.remove(CLASSES.NAV_DARK_BG)
        }
    }

    /**
     * @desc Handle key down for menu to set proper focus
     */
    handleMenuKeyDown(e) {
        const keyCode = e.keyCode || e.which

        if (
            this.el.classList.contains(
                GLOBAL_CONSTANTS.CLASSES.MOBILE_NAV_ACTIVE,
            ) &&
            keyCode === 27
        ) {
            // Handles escape key
            this.closeNavMenu()
            this.navMenuToggle.focus()
        } else if (
            e.target === this.tabItems[this.tabItems.length - 1] &&
            !e.shiftKey &&
            keyCode === 9
        ) {
            // Handles tabbing past the last menu item to return to menu toggle button

            event.preventDefault()
            this.tabItems[0].focus()
        } else if (
            e.target === this.tabItems[0] &&
            e.shiftKey &&
            keyCode === 9
        ) {
            // Handles shift-tabbing past the first menu item
            event.preventDefault()
            this.tabItems[this.tabItems.length - 1].focus()
        } else if (keyCode === 32) {
            // Handles spacebar for click
            e.target.click()
        }
    }

    updateNavActiveState({ url }) {
        Object.keys(this.navLinkMap).forEach((key) => {
            const item = this.navLinkMap[key]
            item.el.classList.remove(GLOBAL_CONSTANTS.CLASSES.ACTIVE)
            item.anchor.removeAttribute('aria-selected')
        })

        this.activeLink = this.navLinkMap[url]

        if (this.activeLink) {
            this.activeLink.el.classList.add(GLOBAL_CONSTANTS.CLASSES.ACTIVE)
            this.activeLink.anchor.setAttribute('aria-selected', true)
        }
    }

    openSearchBar() {
        this.el.classList.add(GLOBAL_CONSTANTS.CLASSES.SEARCH_ACTIVE)
        this.searchScrim.classList.add(GLOBAL_CONSTANTS.CLASSES.ACTIVE)
        this.searchWrapper.classList.add(GLOBAL_CONSTANTS.CLASSES.ACTIVE)
        this.getSearchForm()
        this.searchForm.querySelector('input.js-search-input').focus()
    }

    getSearchForm() {
        if (!this.searchForm.innerHTML) {
            const xhr = new XMLHttpRequest()
            xhr.open('GET', SEARCH_FORM_URL)
            xhr.onload = function () {
                this.searchForm.innerHTML = xhr.responseText
                document.body.classList.add(GLOBAL_CONSTANTS.CLASSES.SEARCH_ACTIVE)
                new RealTimeSearch(document.querySelector('.real-time-search__wrapper'))
                this.searchForm.querySelector('input.js-search-input').focus()
            }.bind(this)
            xhr.send()
        }
    }

    closeSearchBar() {
        if (
            this.el.classList.contains(GLOBAL_CONSTANTS.CLASSES.SEARCH_ACTIVE)
        ) {
            this.searchScrim.classList.remove(GLOBAL_CONSTANTS.CLASSES.ACTIVE)
            this.searchWrapper.classList.remove(GLOBAL_CONSTANTS.CLASSES.ACTIVE)

            this.el.classList.remove(GLOBAL_CONSTANTS.CLASSES.SEARCH_ACTIVE)
        }
    }

    toggleSearchBar(event) {
        event.preventDefault()

        if (
            !this.el.classList.contains(
                GLOBAL_CONSTANTS.CLASSES.SEARCH_ACTIVE,
            )
        ) {
            requestAnimationFrame(this.openSearchBar)
        } else {
            requestAnimationFrame(this.closeSearchBar)
            Emitter.emit('close-search-content')
        }
    }

    /**
     * @desc Handles orientation change by firing our handleScroll function
     */
    handleOrientationChange() {
        this.handleScroll(true)
    }

    /**
     * @desc Scroll event that determines if the nav should show or hide.
     */
    handleScroll(hasOrientationChanged) {
        if (hasOrientationChanged) {
            this.setActive(false)
            return
        }

        if (this.position === window.pageYOffset) {
            return
        }

        const pos = window.pageYOffset
        const isScrollingUp = pos < this.position

        if (
            this.el.classList.contains(GLOBAL_CONSTANTS.CLASSES.SEARCH_ACTIVE)
        ) {
            // todo: this.closeSearchBar()
        }
        if (isScrollingUp) {
            this.scrollUpCount += this.position - pos
        } else {
            this.scrollUpCount = 0
        }

        const isScrollUpThresholdMet = this.scrollUpCount >= SCROLL_UP_BUFFER

        const NAV_HEIGHT = this.el.getBoundingClientRect().height

        if (pos > NAV_HEIGHT) {
            this.setActive(isScrollUpThresholdMet)
            this.setFixed(true)
        } else if ((isScrollingUp && pos === 0) || pos <= 1) {
            this.setActive(false)
            this.setFixed(false)
        }

        this.position = window.pageYOffset
    }

    toggleNavMenu(e) {
        e.preventDefault()
        if (
            this.el.classList.contains(
                GLOBAL_CONSTANTS.CLASSES.MOBILE_NAV_ACTIVE,
            )
        ) {
            this.closeNavMenu()
        } else {
            this.openNavMenu()
            this.closeSearchBar()
        }
    }

    closeNavMenu() {
        Emitter.emit('close-search-content')
        Emitter.off('keydown', this.handleMenuKeyDown)
        this.el.classList.remove(GLOBAL_CONSTANTS.CLASSES.MOBILE_NAV_ACTIVE)

        this.navMenuToggle.setAttribute('aria-expanded', false)
        this.navMenuToggle.setAttribute('aria-label', 'Open Menu')
        this.navMenu.setAttribute('aria-hidden', true)

        enableBodyScroll(this.navMenu)
        this.navTimeout = setTimeout(() => {
            this.navMenu.classList.remove(GLOBAL_CONSTANTS.CLASSES.VISIBLE)
        }, GLOBAL_CONSTANTS.TIMING.STD_ANIM_TIME)
    }

    openNavMenu() {
        Emitter.on('keydown', this.handleMenuKeyDown)
        clearTimeout(this.navTimeout)

        this.el.classList.add(GLOBAL_CONSTANTS.CLASSES.MOBILE_NAV_ACTIVE)

        this.navMenuToggle.setAttribute('aria-expanded', true)
        this.navMenuToggle.setAttribute('aria-label', 'Close Menu')
        this.navMenu.setAttribute('aria-hidden', false)
        this.navMenu.classList.add(GLOBAL_CONSTANTS.CLASSES.VISIBLE)

        disableBodyScroll(this.navMenu)
    }

    setActive(isActive) {
        this.isActive = isActive
        if (isActive) {
            this.el.classList.add(GLOBAL_CONSTANTS.CLASSES.ACTIVE)
            document.body.classList.add(GLOBAL_CONSTANTS.CLASSES.HEADER_COMPACT)

        } else {
            this.el.classList.remove(GLOBAL_CONSTANTS.CLASSES.ACTIVE)
            document.body.classList.remove(GLOBAL_CONSTANTS.CLASSES.HEADER_COMPACT)
        }
    }

    setFixed(isFixed) {
        this.isFixed = isFixed
        if (isFixed) {
            this.el.classList.add(GLOBAL_CONSTANTS.CLASSES.FIXED)
            document.body.classList.remove(GLOBAL_CONSTANTS.CLASSES.HEADER_FULL)
        } else {
            this.el.classList.remove(GLOBAL_CONSTANTS.CLASSES.FIXED)
            document.body.classList.add(GLOBAL_CONSTANTS.CLASSES.HEADER_FULL)
        }
    }

    /**
     * @desc Tear down the event listeners
     */
    tearDown() {
        Emitter.off(
            GLOBAL_CONSTANTS.EVENTS.PAGE_BEFORE_LEAVE,
            this.onBeforePageLeave,
        )
        Emitter.off(
            GLOBAL_CONSTANTS.EVENTS.PAGE_LEAVE,
            this.updateNavActiveState,
        )
        Emitter.off(
            GLOBAL_CONSTANTS.EVENTS.PAGE_AFTER_ENTER,
            this.updateNavBackground,
        )
        Emitter.off(
            GLOBAL_CONSTANTS.EVENTS.PAGE_LOADING,
            this.updateNavBackground,
        )
        Emitter.off(GLOBAL_CONSTANTS.EVENTS.SCROLL, this.handleScroll)

        clearAllBodyScrollLocks()
    }
}

/**
 * @desc Test component definition used in module-loader
 */

export const NavComponent = {
    name: 'Nav',
    componentClass: CLASSES.COMPONENT,
    Source: Nav,
}
