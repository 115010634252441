import { isDesktop } from '../utils/constants'

const SELECTORS = {
    COMPONENT: '.featured-companies-carousel__carousel__list',
}

export default class FeaturedCompaniesCarousel {
    constructor(element) {
        this.element = element
        this.parentRow = document.querySelector('.featured-companies-carousel__carousel__offset-parent')
        Array.from(this.element.children).forEach(item => this.setup(item))
        this.scrollHeight = this.element.children[0].offsetHeight
        this.isPaused = false
        this.scroll()
    }

    tearDown() {
        document.removeEventListener('mousemove', this.mouseMoveHandler)
    }

    scroll() {
        if (!this.isPaused) {
            this.element.scrollTop += 1
            if (this.element.scrollTop === this.scrollHeight) {
                const firstItem = this.element.children[0]
                this.element.removeChild(firstItem)
                let clonedItem = this.setup(firstItem.cloneNode(true))
                this.element.appendChild(clonedItem)
                this.element.scrollTop = 0
            }
        }
        requestAnimationFrame(() => this.scroll())
    }

    mouseMoveHandler = (event) => {
        const index = event.target.dataset.index
        const logoContainerSelector = '.featured-companies-carousel__company-logo__container'
        const tooltip = document.querySelector(`${logoContainerSelector}[data-index='${index}']`)
        const logo = document.querySelector(`img.tooltip-company-logo[data-index='${index}']`)
        const rect = this.parentRow.getBoundingClientRect()
        if (!tooltip || !logo || !rect) {
            // in any of these unlikely cases, hide the tooltip but keep the carousel paused for clicks
            const logoContainer = event.target.parentElement.querySelector(logoContainerSelector)
            if (logoContainer) {
                logoContainer.classList.remove('active')
            }
            return
        }
        const logoWidth = logo.offsetWidth
        const logoHeight = logo.offsetHeight
        const extraOffset = 2 // move away from the cursor a bit to avoid flickering
        const offsetX = event.clientX - rect.left - logoWidth - extraOffset
        const offsetY = event.clientY - rect.top - logoHeight - extraOffset

        tooltip.style.left = offsetX + 'px'
        tooltip.style.top = offsetY + 'px'
    }

    setup = (item) => {
        if (!isDesktop()) {
            return item
        }

        let tooltip = item.querySelector('.featured-companies-carousel__company-logo__container')
        let detectionZone = item.querySelector('.hover-detection-zone')

        detectionZone.addEventListener('mouseenter', () => {
            this.isPaused = true
            if (tooltip) {
                tooltip.classList.add('active')
                document.addEventListener('mousemove', this.mouseMoveHandler)
            }
        })
        detectionZone.addEventListener('mouseleave', () => {
            this.isPaused = false
            if (tooltip) {
                tooltip.classList.remove('active')
                document.removeEventListener('mousemove', this.mouseMoveHandler)
            }
        })

        return item
    }
}

export const FeaturedCompaniesCarouselComponent = {
    name: 'FeaturedCompaniesCarouselComponent',
    componentClass: SELECTORS.COMPONENT,
    Source: FeaturedCompaniesCarousel,
}
