/*
    global Vimeo
 */

import Emitter from 'utils/emitter'


export const MULTI_VIDEO_CONSTANTS = {
    EVENTS: {
        VIDEO_PLAY: 'video_play',
        VIDEO_PAUSE: 'video_paused',
        VIDEO_TIMEUPDATE: 'video_timeupdate',
    },
}

const CLASSES = {
    COMPONENT: '.multi-video',
}

export default class MultiVideo {
    constructor(el) {
        this.wrapper = el
        Emitter.on('onExternalScriptsLoaded', () => this.init())
    }

    init() {
        this.playerContainer = this.wrapper.querySelector('.player_container')
        this.playlistContainer = this.wrapper.querySelector('.playlist')

        const loadVideoItem = this.loadVideoItem.bind(this)
        const setVideoInMainPlayer = this.setVideoInMainPlayer.bind(this)
        this.closeFloatingPlayerClickHandler = this.closeFloatingPlayerClickHandler.bind(this)

        this.enablePicInPic = !!(parseInt(this.playerContainer.dataset.enablePicInPic, 10))

        const playlistItems = this.playlistContainer.querySelectorAll('.playlist-item')
        playlistItems.forEach(function (item) {
            loadVideoItem(item)
        })

        if (playlistItems.length > 1) {
            // We default to "1" to make the URL parameter a more human-friendly experience. We take away 1 to convert
            // it into a zero-index counter for the array index.
            let defaultVideo = (parseInt(this.playerContainer.dataset.defaultVideo, 10) || 1) - 1
            if (!playlistItems[defaultVideo]) {
                defaultVideo = 0
            }
            // If the default is not the first, then scroll it into view.
            // If it is the first (or its not set in the URL) then we should not commandeer the scroll on load.
            if (defaultVideo > 0) {
                playlistItems[defaultVideo].scrollIntoView({behavior: 'smooth', inline: 'center'})
            }

            // We only load the Main Player if there is >1 playlist item (a single playlist item is styled to be full size)
            // or if the browser size is "desktop". Mobiles only see a playlist of playable videos.
            if (window.innerWidth >= 768) {
                // Only autoscroll the window to the video if we are preloading the non-first one.
                let autoscroll = (defaultVideo > 0)
                setVideoInMainPlayer(playlistItems[defaultVideo], autoscroll)
            }
        } else {
            // If there is only 1 video, then we use the thumbnail as the main video.
            this.playerContainer = playlistItems[0]
        }

        if (this.enablePicInPic) {
            Emitter.on('scroll', () => {
                this.onScroll()
            })
            this.onScroll()
        }
    }

    onScroll() {
        const playerRect = this.playerContainer.getBoundingClientRect()
        const playerMiddleOffset = (playerRect.top + playerRect.bottom) / 2

        // Page is below the main player, switch to floating if it's not already floating.
        if (playerMiddleOffset < 0) {
            if (!this.playerContainer.classList.contains('floating-player-is-closed')) {
                if (!this.playerContainer.classList.contains('floating-player')) {
                    this.playerContainer.classList.add('floating-player')
                    this.playerContainer.querySelectorAll('.floating-player-close').forEach(el => el.style.opacity = 1)
                }
            }
        }
        // Scroll is above the middle of the player, remove floating if its set
        else {
            if (this.playerContainer.classList.contains('floating-player')) {
                this.playerContainer.classList.remove('floating-player')
            }
            // Reset the closed state
            if (this.playerContainer.classList.contains('floating-player-is-closed')) {
                this.playerContainer.classList.remove('floating-player-is-closed')
            }
            this.playerContainer.querySelectorAll('.floating-player-close').forEach(el => el.style.opacity = 0)
        }
    }

    closeFloatingPlayerClickHandler() {
        if (this.playerContainer.classList.contains('floating-player')) {
            this.playerContainer.classList.remove('floating-player')
        }
        if (!this.playerContainer.classList.contains('floating-player-is-closed')) {
            this.playerContainer.classList.add('floating-player-is-closed')
        }
        this.playerContainer.querySelectorAll('.floating-player-close').forEach(el => el.style.opacity = 0)
    }

    loadVideoItem(playlistEl) {
        const videoId = playlistEl.dataset.videoId
        const provider = playlistEl.dataset.provider
        const clickCallback = this.playlistItemClickHandler.bind(this)
        const renderVideo = this.renderVideo.bind(this)
        var oembedUrls = {
            'youtube': 'https://www.youtube.com/oembed?format=json&url=https%3A//youtube.com/watch%3Fv%3D',
            'vimeo': 'https://vimeo.com/api/oembed.json?dnt=1&url=https://player.vimeo.com/video/',
        }

        var oembedUrl = oembedUrls[provider]
        if (!oembedUrl) {
            throw Error('Unsupported video provider: ' + provider)
        }

        var xhr = new XMLHttpRequest()
        xhr.open('GET', oembedUrl + videoId, true)

        xhr.onload = function (e) {
            if (e.target.readyState === 4) {
                if (e.target.status === 200) {
                    const parsedData = JSON.parse(e.target.responseText)

                    const smallVideo = renderVideo(provider, videoId)
                    smallVideo.classList.add('small-video', 'col-xs--show', 'col-md--hide')
                    playlistEl.appendChild(smallVideo)
                    if (provider === 'vimeo') {
                        // Vimeo is loaded in the template.
                        let player = new Vimeo.Player(smallVideo)
                        player.on('play', function (e) {
                            window.history.pushState('', '', '?video=' + playlistEl.dataset.playlistId)
                            Emitter.emit(MULTI_VIDEO_CONSTANTS.EVENTS.VIDEO_PLAY, 'vimeo', videoId, e)
                        })
                        player.on('pause', function (e) {
                            Emitter.emit(MULTI_VIDEO_CONSTANTS.EVENTS.VIDEO_PAUSE, 'vimeo', videoId, e)
                        })
                        player.on('timeupdate', function (e) {
                            Emitter.emit(MULTI_VIDEO_CONSTANTS.EVENTS.VIDEO_TIMEUPDATE, 'vimeo', videoId, e)
                        })
                    } else if (provider === 'youtube' && typeof window.YT !== 'undefined') {
                        window.YT.ready(function () {
                            const iFrame = smallVideo.querySelector('iframe')
                            new window.YT.Player(iFrame.id, {
                                events: {
                                    'onStateChange': function (e) {
                                        if (e.data === window.YT.PlayerState.PLAYING) {
                                            window.history.pushState('', '', '?video=' + playlistEl.dataset.playlistId)
                                            Emitter.emit(MULTI_VIDEO_CONSTANTS.EVENTS.VIDEO_PLAY, e)
                                        }
                                    },
                                },
                            })
                        })
                    }

                    const imgContainerDiv = document.createElement('div')
                    imgContainerDiv.className = 'thumbnail-background-' + provider
                    const img = document.createElement('img')
                    img.src = parsedData.thumbnail_url || window.videoThumbnailFallbackImageUrl
                    img.onerror = () => {
                        // Valid YouTube thumbnail has this dimensions, to unify the look we had to pad Vimeo thumbnails,
                        // easier to style them all the same here, than reverting the styles of Vimeo thumbnails if YouTube thumbnail failed.
                        img.style.aspectRatio = '4/3'
                        img.style.backgroundColor = '#0f0f0f'
                        img.src = window.videoThumbnailFallbackImageUrl
                    }
                    img.className = 'thumbnail col-xs--hide col-md--show thumbnail-' + provider
                    imgContainerDiv.appendChild(img)
                    playlistEl.appendChild(imgContainerDiv)

                    const title = document.createElement('div')
                    title.className = 'title'
                    if (playlistEl.dataset.headline !== undefined) {
                        title.innerText = playlistEl.dataset.headline
                    } else {
                        title.innerText = parsedData.title
                    }
                    playlistEl.appendChild(title)

                    if (playlistEl.dataset.description) {
                        const description = document.createElement('div')
                        description.className = 'video__description'
                        description.innerHTML = playlistEl.dataset.description
                        playlistEl.appendChild(description)
                    }

                    playlistEl.addEventListener('click', clickCallback, {capture: true})
                } else {
                    console.error(e.target.statusText)
                }
            }
        }
        xhr.onerror = function (e) {
            console.error(e.target.statusText)
        }
        xhr.send(null)
    }

    setVideoInMainPlayer(playlistElement, autoscroll) {
        const videoWrapper = this.renderVideo(playlistElement.dataset.provider, playlistElement.dataset.videoId)
        this.playerContainer.innerHTML = ''
        this.playerContainer.appendChild(videoWrapper)
        if (autoscroll) {
            videoWrapper.addEventListener('load', function () {
                videoWrapper.scrollIntoView({behavior: 'smooth', block: 'center'})
            }, true)
        }
        let titleElement = playlistElement.getElementsByClassName('title')
        if (titleElement.length) {
            document.title = titleElement[0].innerHTML
        }
    }

    playlistItemClickHandler(e) {
        window.history.pushState('', '', '?video=' + e.currentTarget.dataset.playlistId)

        if (window.innerWidth >= 768) {
            this.setVideoInMainPlayer(e.currentTarget, true)
        }
    }

    renderVideo(provider, id) {
        const videoWrapper = document.createElement('div')
        videoWrapper.className = 'video-wrapper ' + provider

        const iFrameWrapper = document.createElement('div')
        iFrameWrapper.className = 'iframe-wrapper'

        const closeButton = document.createElement('div')
        closeButton.classList.add('floating-player-close')
        closeButton.addEventListener('click', this.closeFloatingPlayerClickHandler)

        const svg = document.createElementNS('http://www.w3.org/2000/svg', 'svg')
        const path1 = document.createElementNS('http://www.w3.org/2000/svg', 'path')

        svg.setAttribute('aria-hidden', 'true')
        svg.setAttribute('viewbox', '0 0 14 14')
        svg.setAttribute('width', '14px')
        svg.setAttribute('height', '14px')

        path1.setAttribute('d', 'M0 0 L14 14 M0 14 L14 0Z')
        path1.setAttribute('stroke-width', '2px')

        svg.appendChild(path1)

        closeButton.appendChild(svg)
        iFrameWrapper.appendChild(closeButton)

        const videoIFrame = document.createElement('iframe')
        videoIFrame.setAttribute('frameBorder', 0)
        videoIFrame.setAttribute('allow', 'autoplay; fullscreen; accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture')
        videoIFrame.setAttribute('allowfullscreen', '')
        videoIFrame.setAttribute('id', 'multi_video__' + provider + '__' + id)

        if (provider === 'vimeo') {
            videoIFrame.setAttribute('src', 'https://player.vimeo.com/video/' + id + '?dnt=1&playsinline=0')
        } else if (provider === 'youtube') {
            videoIFrame.setAttribute('src', 'https://www.youtube.com/embed/' + id + '?feature=oembed&enablejsapi=1&playsinline=0')
        } else {
            throw Error('Unsupported Provider: ' + provider)
        }

        iFrameWrapper.appendChild(videoIFrame)
        videoWrapper.appendChild(iFrameWrapper)

        return videoWrapper
    }
}

export const MultiVideoComponent = {
    name: 'MultiVideo',
    componentClass: CLASSES.COMPONENT,
    Source: MultiVideo,
}
