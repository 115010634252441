const CLASSES = {
    COMPONENT: '.js-scroll-to-element',
    ACTIVE_CLASS: 'js-scroll-to-element-active',
    PANEL_SELECTOR: '.js-scroll-to-element-panel',
    SCROLL_WRAPPER_SELECTOR: '.js-scroll-to-element-wrapper',
}

export default class ScrollToElement {
    constructor(element) {
        this.element = element
        this.targetSectionId = element.dataset['jsScrollToElement'] || false
        this.targetSectionElement = false
        let scrollWrapper = window

        if (this.targetSectionId) {
            this.targetSectionElement = document.getElementById(this.targetSectionId)
            this.observer = this.registerObserver()
            this.observer.observe(this.targetSectionElement)
            const targetSectionScrollWrapper = this.targetSectionElement.closest(CLASSES.SCROLL_WRAPPER_SELECTOR)
            if (targetSectionScrollWrapper) {
                scrollWrapper = targetSectionScrollWrapper
            }
        }

        element.addEventListener('click', (event) => {
            if (this.targetSectionId && this.targetSectionElement) {
                const padding = parseInt(window.getComputedStyle(this.targetSectionElement).marginTop || this.targetSectionElement.currentStyle.marginTop)
                const filterPanel = (scrollWrapper.hasOwnProperty('closest') && scrollWrapper.closest(CLASSES.PANEL_SELECTOR)) || document.querySelector(CLASSES.PANEL_SELECTOR)
                const filterPanelHeight = filterPanel ? filterPanel.offsetHeight : 0
                scrollWrapper.scrollTo({
                    top: this.targetSectionElement.offsetTop - padding - filterPanelHeight,
                    behavior: 'smooth',
                })
            }
            event.stopPropagation()
            event.preventDefault()
        })
    }

    /**
     * @desc Creates an observer in which we can register our elements against
     * @param {HTMLElement} el - Image element
     */
    registerObserver() {
        const options = {
            root: null,
            rootMargin: '-10% 0px -20% 0px',
            threshold: [0.01, 0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 0.99],
        }

        return new IntersectionObserver(this.elementInView.bind(this), options)
    }

    /**
     * @desc Callback for intersectionObserver that says the section is in the viewport
     * and is ready to be displayed.
     * @param {HTMLElement} entries - List of image entries from the observer
     */
    elementInView(entries) {
        // console.log('entries', entries)
        // console.log('observer', observer)
        let intersectionRatio = 0
        entries.forEach((entry) => {
            intersectionRatio = 10 * Math.round(entry.intersectionRatio * 10)
            entry.target.dataset.intersectionRatio = intersectionRatio
        })
        this.setActiveElement()
    }

    /**
     * @desc If the section is in the viewport, set active top nav link
     * @param {HTMLElement} The section that is in the viewport
     */
    activateElement() {
        document.querySelectorAll(CLASSES.COMPONENT).forEach(function (linkElement) {
            linkElement.classList.remove(CLASSES.ACTIVE_CLASS)
        })

        this.element.classList.add(CLASSES.ACTIVE_CLASS)
    }

    setActiveElement() {
        let maxIntersectionRatio = 0
        let activeElementIds = []
        document.querySelectorAll('[data-intersection-ratio]').forEach(function (sectionElement) {
            let intersectionRatio = parseInt(sectionElement.dataset.intersectionRatio)
            if (intersectionRatio > maxIntersectionRatio) {
                maxIntersectionRatio = intersectionRatio
                activeElementIds = [sectionElement.id]
                if (sectionElement.dataset.jsScrollToElementParentId) {
                    // set data attribute on the children elements holding the id of the parent element, to set parent element to active if any of the children is active
                    activeElementIds.push(sectionElement.dataset.jsScrollToElementParentId)
                }
            }
        })
        document.querySelectorAll(CLASSES.COMPONENT).forEach(function (linkElement) {
            linkElement.classList.remove(CLASSES.ACTIVE_CLASS)
        })
        activeElementIds.forEach(function (activeElementId) {
            let activeElement = document.querySelector(`[data-js-scroll-to-element="${activeElementId}"`)
            if (activeElement) {
                activeElement.classList.add(CLASSES.ACTIVE_CLASS)
            }
        })
    }
}

export const ScrollToElementComponent = {
    name: 'ScrollToElement',
    componentClass: CLASSES.COMPONENT,
    Source: ScrollToElement,
}
