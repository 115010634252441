export let CLASSES = {
    PODCAST_BLOCK: '.js-press-podcast',
    EPISODES_LIST: '.js-press-podcast-episodes-list',
    LIST_EPISODE: '.js-press-podcast-episodes-list-episode',
    LIST_EPISODE_STOPPED: '.js-press-podcast-episodes-list-episode-stopped',
    LIST_EPISODE_PLAYING: '.js-press-podcast-episodes-list-episode-playing',
    EPISODE: '.js-press-podcast-episode',
}

export default class PressPodcast {
    constructor(el) {
        this.podcastBlock = el
        this.listEpisodes = this.podcastBlock.querySelectorAll(CLASSES.LIST_EPISODE)
        this.episodes = this.podcastBlock.querySelectorAll(CLASSES.EPISODE)

        this.addDurations()
    }

    addDurations() {
        this.listEpisodes.forEach(listEpisode => {
            const episode = this.getEpisode(listEpisode.dataset.ordinal)
            this.setDuration(listEpisode, episode)
        })
    }

    setDuration(listEpisode, episode) {
        let duration = episode.querySelector('template').content.querySelector('iframe').dataset.simplecastDuration
        if (!duration) {
            return
        }
        let durationString = new Date(parseInt(duration) * 1000).toISOString()
        durationString = durationString.substring(11, 19) // 01:23:45
        if (duration < 3600) { // less than an hour
            durationString = durationString.substring(3) // 23:45
        }
        listEpisode.querySelector('.js-press-podcast-episodes-list-episode-duration').textContent = durationString
    }

    getEpisode(episodeOrdinal) {
        const episodes = [...this.episodes]
        return episodes.find(episode => episode.dataset.ordinal === episodeOrdinal)
    }
}

export const PressPodcastComponent = {
    name: 'PressPodcast',
    componentClass: CLASSES.PODCAST_BLOCK,
    Source: PressPodcast,
}
