import WaveSurfer from 'wavesurfer.js'

let CLASSES = {
    COMPONENT: '.js-press-audio',
    AUDIO_PLAY: '.js-press-audio-play',
    AUDIO_PAUSE: '.js-press-audio-pause',
    AUDIO_PLAYER: '.js-press-audio-player',
}

const wavesurferOptions = {
    'height': 36,
    'splitChannels': false,
    'normalize': true,
    'waveColor': '#666666',
    'progressColor': '#cccccc',
    'cursorColor': '#ffffff',
    'cursorWidth': 3,
    'barWidth': 3,
    'barGap': 3,
    'barRadius': 3,
    'barHeight': 1,
    'barAlign': '',
    'minPxPerSec': 1,
    'fillParent': true,
    'mediaControls': false,
    'autoplay': false,
    'interact': true,
    'dragToSeek': true,
    'hideScrollbar': true,
    'audioRate': 1,
    'autoScroll': true,
    'autoCenter': true,
    'sampleRate': 8000,
}

export default class PressAudio {
    constructor(el) {
        this.audio = el
        this.audioPlay = el.querySelectorAll(CLASSES.AUDIO_PLAY)[0]
        this.audioPause = el.querySelectorAll(CLASSES.AUDIO_PAUSE)[0]
        this.audioPlayer = el.querySelectorAll(CLASSES.AUDIO_PLAYER)[0]

        this.onAudioPlay = this.onAudioPlay.bind(this)
        this.onAudioPause = this.onAudioPause.bind(this)

        this.audioPlay.removeEventListener('mousedown', this.onAudioPlay)
        this.audioPlay.addEventListener('mousedown', this.onAudioPlay)

        this.audioPause.removeEventListener('mousedown', this.onAudioPause)
        this.audioPause.addEventListener('mousedown', this.onAudioPause)

        if (this.audioPlayer.dataset['url']) {
            this.audioPlayer.wavesurfer = WaveSurfer.create({
                'container': this.audioPlayer,
                'url': this.audioPlayer.dataset['url'],
                ...wavesurferOptions,
            })
            this.audioPlayer.wavesurfer.on('finish', () => {
                this.audioPlayer.wavesurfer.setTime(0)
                this.onAudioPause()
            })
        }
    }

    onAudioPlay() {
        this.audio.classList.remove('is-paused')
        this.audio.classList.add('is-playing')
        this.audioPlayer.wavesurfer.play()
    }

    onAudioPause() {
        this.audio.classList.remove('is-playing')
        this.audio.classList.add('is-paused')
        this.audioPlayer.wavesurfer.pause()
    }

    tearDown() {
        this.audioPlay.removeEventListener('mousedown', this.onAudioPlay)
        this.audioPause.removeEventListener('mousedown', this.onAudioPause)
    }
}

export const PressAudioComponent = {
    name: 'PressAudio',
    componentClass: CLASSES.COMPONENT,
    Source: PressAudio,
}