import Cookies from 'js-cookie'
import Emitter from 'utils/emitter'
import { POPUP_CONSTANTS } from 'components/Popup'
import { GLOBAL_CONSTANTS } from 'utils/constants'

const SELECTORS = {
    COMPONENT: 'body',
}

export const POPUP_NEWSLETTER_CONSTANTS = {
    URLS: {
        NEWSLETTER_FORM: '/newsletter-form/',
    },
}

// Using a STATE_CACHE as the popups persist between Barba page loads. However,
// the JS system tear's down and reconstructs all these classes on every page
// which means we need some way of persisting state between pages.
let STATE_CACHE = undefined

export default class PopupNewsletter {
    constructor() {
        if (STATE_CACHE === undefined) {
            STATE_CACHE = {
                form: null,
            }
        }
        this.registerPopup()
    }

    registerPopup() {
        Emitter.emit(POPUP_CONSTANTS.EVENTS.REGISTER_POPUP, {
            name: 'newsletter',
            popupContent: this.popupContent.bind(this),
            displayAfter: 1000,
            scrollThreshold: 0.33,
            pathnameRegex: new RegExp('/perspectives/.*'),
        })

        Emitter.on(GLOBAL_CONSTANTS.EVENTS.PAGE_AFTER_ENTER, () => {
            this.updateFormContext()
        })
    }

    updateFormContext() {
        // This synchronises the hidden fields on the form to the current page context (URI and Title)
        if (!this.formElement) {
            return
        }
        this.formElement.querySelector('input[name=page_uri]').value = window.location
        this.formElement.querySelector('input[name=page_name]').value = document.title
    }

    async getForm() {
        const xhr = new XMLHttpRequest()
        xhr.open('GET', POPUP_NEWSLETTER_CONSTANTS.URLS.NEWSLETTER_FORM)
        const xhrPromise = new Promise((resolve) => {
            xhr.onload = function () {
                resolve(xhr.responseText)
                STATE_CACHE.form = xhr.responseText
            }.bind(this)
        })
        xhr.send()
        return await xhrPromise
    }

    setupForm(container) {
        this.formElement = container.querySelector('form')
        this.formElement.addEventListener('submit', this.formSubmitHandler.bind(this))

        // Only enable the submit button if the value looks email-ish
        const submitButton = this.formElement.querySelector('input[type=submit]')
        this.formElement.querySelector('input[name=email]').addEventListener('input', function (e) {
            // JS needs us to set disabled, so "negate" the regex that's looking for an email address.
            submitButton.disabled = !(/^\S+@\S+\.\S+$/.test(e.target.value))
        })

        // utk may not be available due to adblockers
        let hubspotutk = Cookies.get('hubspotutk')
        if (hubspotutk) {
            this.formElement.querySelector('input[name=hutk]').value = hubspotutk
        }
        this.updateFormContext()
    }


    popupContent() {
        let container = document.createElement('div')
        container.classList.add('row', 'row-xs--center', '-bg-color-charcoal')
        container.innerHTML = '<div class="col-xs-20"><div class="row row-xs--middle" id="hubspot_newsletter_form"></div></div>'

        this.getForm().then((form) => {
            container.querySelector('#hubspot_newsletter_form').innerHTML = form
            this.setupForm(container)}
        )

        return container
    }

    formSubmitHandler(event) {
        event.preventDefault()

        const submitButton = this.formElement.querySelector('input[type=submit]')
        submitButton.disabled = true
        submitButton.dataset.originalLabel = submitButton.value
        submitButton.value = 'Sending...'

        this.formElement.querySelectorAll('span.error').forEach(function (el) {
            el.remove()
        })

        let data = {
            email: event.target.elements.email.value,
            hutk: event.target.elements.hutk.value,
            page_uri: event.target.elements.page_uri.value, // eslint-disable-line camelcase
            page_name: event.target.elements.page_name.value, // eslint-disable-line camelcase
        }
        data = Object.keys(data).map(function (k) {
            return encodeURIComponent(k) + '=' + encodeURIComponent(data[k])
        }).join('&')

        const xhr = new XMLHttpRequest()
        xhr.open('POST', POPUP_NEWSLETTER_CONSTANTS.URLS.NEWSLETTER_FORM, true)
        xhr.onload = this.newsletterResponseHandler.bind(this)
        xhr.setRequestHeader('X-CSRFToken', event.target.elements.csrfmiddlewaretoken.value)
        xhr.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8')
        xhr.send(data)
    }

    createMessage(type, message) {
        const errorMessage = document.createElement('span')
        errorMessage.classList.add('message', type)
        errorMessage.innerText = message
        // Add this error message to the end of the form so it comes under the button.
        this.formElement.append(errorMessage)
    }

    newsletterResponseHandler(e) {
        const response = JSON.parse(e.target.response)
        const that = this

        if (e.target.status !== 200) {
            // Error case
            Object.keys(response.errors).forEach(function (k) {
                that.createMessage('error', response.errors[k].join(', '))
            })
            const submitButton = this.formElement.querySelector('input[type=submit]')
            submitButton.disabled = false
            submitButton.value = submitButton.dataset.originalLabel
        } else {
            this.formElement.querySelectorAll('input').forEach(function (el) {
                el.classList.add('-hidden')
            })
            // All good - show success message and trigger popup close.
            this.createMessage('success', 'Thank you for subscribing.')
            setTimeout(function () {
                Emitter.emit(POPUP_CONSTANTS.EVENTS.CLOSE_POPUP)
            }, 2000)
        }
    }
}

export const PopupNewsletterComponent = {
    name: 'PopupNewsletter',
    componentClass: SELECTORS.COMPONENT,
    Source: PopupNewsletter,
}
