import Cookies from 'js-cookie'

const CLASSES = {
    COMPONENT: '.home-overlay',
    OVERLAY_CONTENT: '.home-overlay__content',
}

const HOME_OVERLAY_COOKIE_NAME = 'home_overlay'

export default class HomeOverlay {
    constructor(el) {
        this.overlay = el
        const HOME_OVERLAY_COOKIE_TIMESTAMP = parseInt(this.overlay.dataset.cookieTimestamp)

        // Turning off for Simon until we know if we want this to hide or not.

        // Bail if a cookie is set (ie a user has already seen this popup)
        // Note: If the homepage is saved/updated it will reset this cookie.
        // Note: HOME_OVERLAY_COOKIE_TIMESTAMP comes from the HTML template.
        // if (Cookies.get(HOME_OVERLAY_COOKIE_NAME) >= HOME_OVERLAY_COOKIE_TIMESTAMP) {
        //     return
        // }

        this.overlayContent = document.querySelector(CLASSES.OVERLAY_CONTENT)
        document.body.classList.add('overlay-is-open')
        this.overlay.classList.add('overlay-is-open')

        let overlaySetCookie = function() {
            // 2038-01-19 is a 32bit date limit for older browsers.
            Cookies.set(HOME_OVERLAY_COOKIE_NAME, HOME_OVERLAY_COOKIE_TIMESTAMP, {
                expires: new Date('2038-01-19T03:14:07'),
                sameSite: 'strict',
                secure: true,
            })
        }

        this.overlay.addEventListener('click', () => {
            this.overlay.classList.remove('overlay-is-open')
            this.overlay.remove()
            document.body.classList.remove('overlay-is-open')
            overlaySetCookie()
        }, { passive: true })

        this.overlayContent.addEventListener('click', (event) => {
            overlaySetCookie()
            event.stopPropagation()
        }, { passive: true })
    }
}

export const HomeOverlayComponent = {
    name: 'HomeOverlay',
    componentClass: CLASSES.COMPONENT,
    Source: HomeOverlay,
}
