import BugsnagPluginVue from '@bugsnag/plugin-vue'

let bugsnagConfig = JSON.parse(document.getElementById('bugsnagConfig').textContent)
export default {
    apiKey: bugsnagConfig.api_key,
    enabledReleaseStages: bugsnagConfig.notify_release_stages,
    autoTrackSessions: (typeof window.gtag !== 'undefined'),
    releaseStage: bugsnagConfig.release_stage,
    plugins: [new BugsnagPluginVue()],
    onError: function (event) {
        return !!event.errors[0].code
    },
}
