const SELECTORS = {
    COMPONENT: '.companies-scroll-block__scroll',
}

export default class CompaniesScroll {
    constructor(element) {
        this.element = element
        this.list = this.element.children[0]
        this.scrollWidth = this.list.children[0].offsetWidth
        if (window.navigator.webdriver === true) {
            // if we're running in a webdriver, don't animate
            return
        }
        this.scroll()
    }

    scroll() {
        this.element.scrollLeft += 1
        if (this.element.scrollLeft >= this.scrollWidth) {
            const firstItem = this.list.children[0]
            this.list.removeChild(firstItem)
            let clonedItem = firstItem.cloneNode(true)
            this.list.appendChild(clonedItem)
            this.scrollWidth = this.list.children[0].offsetWidth
            this.element.scrollLeft = 0
        }
        requestAnimationFrame(() => this.scroll())
    }
}

export const CompaniesScrollComponent = {
    name: 'CompaniesScrollComponent',
    componentClass: SELECTORS.COMPONENT,
    Source: CompaniesScroll,
}
