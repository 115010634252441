import onShareClick from '../utils/share'

const CLASSES = {
    COMPONENT: '.article-post-hero__social',
}

export default class ShareLinks {
    /**
     * @desc Set up social media share elements and bind events.
     * @param {HTMLElement} element - Element containing the links
     *
     */
    constructor() {
        this.element = document.querySelector(CLASSES.COMPONENT)
        this.url = this.element.getAttribute('data-url')
        this.title = this.element.getAttribute('data-title')
        this.handleClick = this.handleClick.bind(this)

        this.setupWebshare()
        this.registerEvents()
    }

    setupWebshare() {
        const setContainerDisplay = (el, display) =>
            el.parentNode.parentElement.style.display = display

        if (!navigator.canShare) {
            this.element.querySelectorAll('.share__links-link--social:not(.webshare)').forEach(el => {
                setContainerDisplay(el, 'inline-block')
            })
            return
        }

        const webshareElement = this.element.querySelector('.share__links-link--social.webshare')
        setContainerDisplay(webshareElement, 'inline-block')
    }

    registerEvents() {
        this.element.querySelectorAll('.share__links-link--social').forEach(link => {
            link.addEventListener('click', this.handleClick)
        })
    }

    handleClick(event) {
        event.preventDefault()
        event.stopPropagation()

        const media = ['twitter', 'facebook', 'linkedin', 'webshare']
        media.forEach(className => {
            if (event.currentTarget.classList.contains(className)) {
                onShareClick(className, this.title, this.url)
            }
        })
    }
}

/**
 * @desc ShareLinks component definition used in module-loader
 */
export const ShareLinksComponent = {
    name: 'ShareLinks',
    componentClass: CLASSES.COMPONENT,
    Source: ShareLinks,
}
