const CLASSES = {
    COMPONENT: '.js-image-textures',
    TEXTURE: '.js-texture',
    SET_TEXTURE: '.js-set-texture',
}

export default class LoadingTextures {
    constructor(element) {
        this.element = element
        this.textures = document.querySelectorAll(CLASSES.TEXTURE)

        this.init()
    }

    init() {
        this.setTextures()
    }

    setTextures() {
        this.textures.forEach((texture) => {
            const url = window.getComputedStyle(texture).backgroundImage.slice(4, -1).replace(/"/g, '')

            texture.parentElement.querySelector(CLASSES.SET_TEXTURE).setAttribute('data-bg', url)
        })
    }
}

export const LoadingTexturesComponent = {
    name: 'LoadingTextures',
    componentClass: CLASSES.COMPONENT,
    Source: LoadingTextures,
}
