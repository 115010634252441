/*
    global cookieConsent
 */

import Emitter from '../utils/emitter'
import Bugsnag from '@bugsnag/js'

const CLASSES = {
    COMPONENT: '.youtube-player, .vimeo-player',
}

export default class VideoPlayer {
    constructor(el) {
        this.playerEl = el
        this.youtube = this.playerEl.classList.contains('youtube-player')
        this.playerContainer = this.playerEl.parentNode
        this.fallbackThumbnail = this.playerEl.querySelector('.video_thumbnail_fallback')
        this.videoThumbnail = this.playerEl.querySelector('.video_thumbnail')
        this.injectThumbnail()
        this.playerEl.addEventListener('click', this.onClick.bind(this))
        Emitter.on('onMediaCookiesRejected', this.removeVideoEmbed.bind(this))
        this.scriptsLoaded = false
        Emitter.on('onExternalScriptsLoaded', () => this.scriptsLoaded = true)
    }

    onClick() {
        if (typeof cookieConsent === 'undefined' || !cookieConsent('media')) {
            return
        }
        if (this.youtube) {
            if (this.scriptsLoaded) {
                this.loadVideoEmbed()
            } else {
                Emitter.on('onExternalScriptsLoaded', this.loadVideoEmbed.bind(this))
            }
        } else {
            this.loadVideoEmbed()
        }
    }

    removeVideoEmbed() {
        if (this.youtube && this.player) {
            this.player.destroy()
        } else {
            let iframe = this.playerContainer.querySelector(`iframe[data-container-element-id="${this.playerEl.dataset.elementId}"]`)
            if (iframe) {
                iframe.parentNode.removeChild(iframe)
                this.showPlayButton()
            }
        }
    }

    hidePlayButton() {
        this.playerEl.querySelector('.play-button').style.display = 'none'
    }

    showPlayButton() {
        this.playerEl.querySelector('.play-button').style.display = 'block'
    }

    loadYoutube() {
        this.playerConfig = {
            host: 'https://www.youtube-nocookie.com',
            videoId: this.playerEl.dataset.videoId,
            playerVars: {
                origin: window.location.host,
                autoplay: 1, // Auto-play the video on load
                controls: 1, // Show pause/play buttons in player
                showinfo: 0, // Hide the video title
                modestbranding: 1, // Hide the Youtube Logo
                fs: 1, // Hide the full screen button
                // Hide closed captions
                cc_load_policy: 0, // eslint-disable-line camelcase
                // Hide the Video Annotations
                iv_load_policy: 3, // eslint-disable-line camelcase
                autohide: 0, // Hide video controls when playing
            },
        }
        if (typeof window.YT !== 'undefined') {
            window.YT.ready(() => {
                this.player = new window.YT.Player(this.playerEl, this.playerConfig)
            })
        }
    }

    loadVimeo() {
        const iframe = document.createElement('iframe')
        const videoId = this.playerEl.dataset.videoId
        const isLive = this.playerEl.dataset.vimeoLiveEvent === 'true' ? 'liveEvent' : 'video'
        const baseUrls = {
            'liveEvent': `https://vimeo.com/event/${videoId}/embed`,
            'video': `https://player.vimeo.com/video/${videoId}`,
        }
        iframe.src = `${baseUrls[isLive]}?dnt=1&color=e42313&autoplay=1&playsinline=1&pip=0&muted=0&background=0&loop=1`
        iframe.style.cssText = 'position: absolute; top: 0; left: 0; width: 100%; height: 100%;'
        iframe.allow = 'autoplay; fullscreen'
        iframe.allowfullscreen = true
        iframe.dataset.containerElementId = this.playerEl.dataset.elementId

        this.hidePlayButton()

        this.playerContainer.appendChild(iframe)
    }

    loadVideoEmbed() {
        if (this.youtube) {
            this.loadYoutube()
        } else {
            this.loadVimeo()
        }
    }

    setThumbnailOrFallback(url) {
        if (!url) {
            this.setFallbackThumbnail()
            return
        }

        if (!this.videoThumbnail.classList.contains('no-image')) {
            // only inject external thumbnail if the image is not an override thumbnail
            return
        }
        const img = new Image()
        img.src = url
        img.onload = () => {
            if (this.videoThumbnail && this.videoThumbnail.src) {
                this.videoThumbnail.src = img.src
            }
        }
        img.onerror = () => {
            this.setFallbackThumbnail()
        }
    }

    setFallbackThumbnail() {
        Bugsnag.notify('Video thumbnail not found for : ' + this.playerEl.dataset.url)
        this.fallbackThumbnail.parentNode.style.display = 'block'
        this.videoThumbnail.style.display = 'none'
    }

    injectYoutubeThumbnail() {
        this.setThumbnailOrFallback(`https://i.ytimg.com/vi/${this.playerEl.dataset.videoId}/hqdefault.jpg`)
    }

    injectVimeoThumbnail() {
        let xhr = new XMLHttpRequest()
        xhr.open('GET', `https://vimeo.com/api/oembed.json?dnt=1&url=https://player.vimeo.com/video/${(this.playerEl.dataset.videoId)}`, true)
        xhr.onload = function (e) {
            if (e.target.readyState !== 4 || e.target.status !== 200) {
                this.setFallbackThumbnail()
                return
            }
            const parsedData = JSON.parse(e.target.responseText)
            this.setThumbnailOrFallback(parsedData.thumbnail_url)
        }.bind(this)
        xhr.onerror = function (e) {
            console.error(e.target.statusText)
        }
        xhr.send(null)
    }

    injectThumbnail() {
        if (this.youtube) {
            this.injectYoutubeThumbnail()
        } else {
            this.injectVimeoThumbnail()
        }
    }
}

export const VideoPlayerComponent = {
    name: 'VideoPlayer',
    componentClass: CLASSES.COMPONENT,
    Source: VideoPlayer,
}
