export default function attachOutboundLinkClickEvents() {
    const links = document.querySelectorAll('a')
    links.forEach(element => {
        const href = element.getAttribute('href')
        if (!href || element.dataset.eventAttached === 'true') {
            return
        }
        if (href.startsWith('http://') || href.startsWith('https://')) {
            element.setAttribute('target', '_blank')
            element.addEventListener('click', () => {
                if (typeof window.gtag !== 'undefined') {
                    window.gtag('event', 'outbound_link_click', {'outbound_link_clicked': href})
                }
            })
            element.dataset.eventAttached = 'true'
        }
    })
}