import { GLOBAL_CONSTANTS } from 'utils/constants'
import Emitter from 'utils/emitter'

const CLASSES = {
    COMPONENT: '.image-base',
    IMAGE_HERO: '.image-bg',
}

const SELECTORS = {
    COMPONENT: '#main-content',
}

export default class LazyLoad {
    /**
     * @desc Set up lazy loading and bind events.
     */
    constructor() {
        this.observer = this.registerObserver()
        Emitter.on(GLOBAL_CONSTANTS.EVENTS.LAZY_LOAD_IMAGE_SCAN, (element) => {
            this.scanElementForImages(element)
        })
        Emitter.emit(GLOBAL_CONSTANTS.EVENTS.LAZY_LOAD_IMAGE_SCAN, document.querySelector(SELECTORS.COMPONENT))
    }

    scanElementForImages(element) {
        if (process.env.NODE_ENV === 'development') {
            console.log('LazyLoad: Scanning for images in: ', element)
        }
        element.querySelectorAll(CLASSES.COMPONENT).forEach((image) => this.observer.observe(image))
        element.querySelectorAll(CLASSES.IMAGE_HERO).forEach((image) => this.observer.observe(image))
    }

    /**
     * @desc Creates an observer in which we can register our elements against
     */
    registerObserver() {
        const options = {
            root: null,
            rootMargin: `${window.innerHeight}px`,
            threshold: 0.0,
        }

        return new IntersectionObserver(this.imageInView.bind(this), options)
    }

    /**
     * @desc Callback for intersectionObserver that says the image is in the viewport
     * and is ready to be displayed.
     * @param {HTMLElement} entries - List of image entries from the observer
     */
    imageInView(entries) {
        const imageLoadedHandler = this.imageLoaded.bind(this)
        entries.forEach((entry) => {
            if (entry.intersectionRatio > 0) {
                imageLoadedHandler(entry.target)
            }
        })
    }

    /**
     * @desc Display the image by adding a class that toggles opacity
     * The timeout is required here for the animation to work correctly.
     * @param {HTMLElement} img - The image that is in the viewport
     */
    imageLoaded(img) {
        this.observer.unobserve(img)
        setTimeout(() => {
            img.classList.add(GLOBAL_CONSTANTS.CLASSES.LOADED)
        }, GLOBAL_CONSTANTS.TIMING.STD_ANIM_TIME)
    }
}

export const LazyLoadComponent = {
    name: 'LazyLoad',
    componentClass: SELECTORS.COMPONENT,
    Source: LazyLoad,
}
