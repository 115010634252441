const CLASSES = {
    COMPONENT: '[data-kicker-link]',
}

export default class KickerLinks {
    constructor(el) {
        this.kicker = el

        if (this.kicker) {
            this.kicker.addEventListener('click', this.onKickerClick)
        }
    }

    onKickerClick = (event) => {
        let link = this.kicker.dataset.kickerLink
        if (link) {
            console.log('CLICK KICKER', link)
            window.location = link
            event.preventDefault()
            event.stopPropagation()
        }
    }
}

export const KickerLinksComponent = {
    name: 'KickerLinks',
    componentClass: CLASSES.COMPONENT,
    Source: KickerLinks,
}
