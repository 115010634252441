const SELECTORS = {
    COMPONENT: '.tabbed-blocks',
}

export default class TabbedBlock {
    /**
     * @desc Handle tabbed block contents
     * @param {HTMLElement} blockElement - Element for the tabbed block container
     */

    constructor(blockElement) {
        this.element = blockElement
        this.contentWrapper = this.element.querySelector('.tabbed-blocks__content')
        this.initialize()
    }

    /**
     * @desc initialize the class functions after global variables are defined
     */
    initialize() {
        this.registerListeners()
    }
    registerListeners() {
        const that = this

        this.element.querySelectorAll('.tabbed-blocks__nav span').forEach((navItem) => {
            navItem.addEventListener('click', function(e) {
                that.element.dataset['showBlock'] = e.target.dataset['blockId']
            })
        })
    }
}

/**
 * @desc Component definition for the TabbedBlock module
 */

export const TabbedBlockComponent = {
    name: 'Tabbed Blocks',
    componentClass: SELECTORS.COMPONENT,
    Source: TabbedBlock,
}
