const CLASSES = {
    COMPONENT: '.accordion',
}

export default class Accordion {
    constructor(el) {
        this.accordion = el
        this.allAccordions = document.querySelectorAll(CLASSES.COMPONENT)
        this.header = el.getElementsByClassName('accordion-header')[0]

        if (this.header) {
            this.header.addEventListener('click', this.onHeaderClick)
        }
    }

    onHeaderClick = () => {
        this.allAccordions.forEach(a => a.classList.remove('is-open'))
        this.accordion.classList.add('is-open')
    }
}

export const AccordionComponent = {
    name: 'Accordion',
    componentClass: CLASSES.COMPONENT,
    Source: Accordion,
}
