export default function attachNavigationClickEvents() {
    const navigationLinks = document.querySelectorAll('[data-navigation-link]')
    navigationLinks.forEach((element) => {
        if (element.dataset.eventAttached === 'true') {
            return
        }
        element.addEventListener('click', () => {
            if (typeof window.gtag !== 'undefined') {
                window.gtag('event', 'navigation_click', {'navigation_link_clicked': element.dataset.navigationLink})
            }
        })
        element.dataset.eventAttached = 'true'
    })
}
