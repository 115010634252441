const CLASSES = {
    COMPONENT: 'body.template-microsite',
}

export default class Microsite {
    constructor(el) {
        this.bodyWraper = el
        const that = this

        this.bodyWraper
            .querySelectorAll('.featured-posts__list')
            .forEach(function(featuredPostsWrapper) {
                that.processFeaturedPosts(featuredPostsWrapper)
            })
    }

    processFeaturedPosts(featuredPostsWrapper) {
        // Because Features posts are used elsewhere, this JS simply adds in the scrolling buttons for Microsite pages
        featuredPostsWrapper.classList.add('swipe-scroll-list')

        featuredPostsWrapper.querySelectorAll('.featured-posts__post')
            .forEach(function(featuredPostWrapper) {
                featuredPostWrapper.classList.add('scroll-list-item')
            })


        let buttons = `<div class="scroll-list-button scroll-list-button-next">
            <div class="scroll-list-button__background"></div>
            <div class="scroll-list-button__icon">
                <svg width="36" height="62" viewBox="0 0 36 62" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M1 61L33.3125 34.0729C35.2316 32.4737 35.2316 29.5263 33.3125 27.9271L1 1" stroke="#181818"/></svg>
            </div>
        </div>
        <div class="scroll-list-button scroll-list-button-previous">
            <div class="scroll-list-button__background"></div>
            <div class="scroll-list-button__icon">
                <svg width="36" height="62" viewBox="0 0 36 62" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M1 61L33.3125 34.0729C35.2316 32.4737 35.2316 29.5263 33.3125 27.9271L1 1" stroke="#181818"/></svg>
            </div>
        </div>`
        featuredPostsWrapper.insertAdjacentHTML('afterend', buttons)
    }
}

export const MicrositeComponent = {
    name: 'Microsite',
    componentClass: CLASSES.COMPONENT,
    Source: Microsite,
}
