/**
 * Generator function for generating tuple-esque array blocks [[],[]]
 * n is the length of the chuncks that you wish to generate
 */

export default function* chunkArray(arr, n) {
    for (let i = 0; i < arr.length; i += n) {
        yield arr.slice(i, i + n)
    }
}
