import FounderCarousel, { CLASSES } from 'utils/FounderCarousel'

CLASSES.COMPONENT = '.js-perspectives-founder-carousel'

const SWIPE_THRESHOLD = 50

export default class PerspectivesFounderCarousel extends FounderCarousel {
    constructor(el) {
        super(el)
        this.disableAnimation()
        super.init()
        this.setupMobileSwipe()
        this.generateController()
        this.updateController()
    }

    setupMobileSwipe() {
        this.founderCarousel.addEventListener('touchstart', this.touchstart.bind(this))
        this.founderCarousel.addEventListener('touchmove', this.touchmove.bind(this))
        this.founderCarousel.addEventListener('touchend', this.touchend.bind(this))
    }

    generateController() {
        if (this.controllers || this.controllerDots) return
        this.controllers = this.founderCarousel.querySelectorAll('.carousel-controller')
        this.controllerDots = []

        if (!this.controllers) return

        if (this.controllers.length === 1) {
            this.removeControllers()
            return
        }

        const dotTemplate = document.createElement('div')
        dotTemplate.classList.add('dot')

        for (let i = 0; i < this.carouselLength; i++) {
            let controllerDots = []

            this.controllers.forEach(controller => {
                const dot = dotTemplate.cloneNode(true)

                dot.addEventListener('click', () => {
                    this.setActive(i)
                    this.startCarousel(0)
                })

                if (i === 0) dot.classList.add('active')
                controller.appendChild(dot)
                controllerDots.push(dot)
            })

            this.controllerDots.push(controllerDots)
        }
    }

    removeControllers() {
        this.controllers.forEach(controller => {
            controller.remove()
        })
    }

    updateController() {
        if (!this.controllerDots) return
        // Update to the current video
        this.controllerDots.forEach((dotSet, j) => {
            dotSet.forEach((dot) => {
                if (j === this.activeDelta) {
                    dot.classList.add('active')
                    return
                }

                dot.classList.remove('active')
            })
        })
    }


    touchstart(e) {
        this.startX = e.touches[0].clientX
    }

    touchmove(e) {
        this.endX = e.touches[0].clientX
    }

    touchend() {
        if (!this.startX || !this.endX) return

        const diff = this.startX - this.endX
        if (Math.abs(diff) < SWIPE_THRESHOLD) return

        this.startX = null
        this.endX = null

        const wrapDelta = (value) =>
            value < 0 ? this.carouselLength - 1 : value % this.carouselLength

        const newDelta = () =>
            wrapDelta(diff > 0 ? this.activeDelta + 1 : this.activeDelta - 1)

        this.setActive(newDelta())
        this.startCarousel(0)
    }
}

export const PerspectivesFounderCarouselComponent = {
    name: 'PerspectivesFounderCarousel',
    componentClass: CLASSES.COMPONENT,
    Source: PerspectivesFounderCarousel,
}
