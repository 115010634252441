/*
    global initializeGA cookieConsent
 */
import Cookies from 'js-cookie'
import { GLOBAL_CONSTANTS } from '../utils/constants'
import Emitter from 'utils/emitter'
import { createWidget } from '@typeform/embed'
import Bugsnag from '@bugsnag/js'

const CLASSES = {
    COMPONENT: '.wtm_cookie_bar_wrapper',
}

export default class CookieControl {
    cookieWhitelist = ['home_overlay', 'csrf_token', 'wtm', 'wtm_id']
    externalScripts = Object.values(GLOBAL_CONSTANTS.EXTERNAL_SCRIPTS)

    constructor() {
        this.mediaCookiesPrompts = document.querySelectorAll('.media-cookies-wrapper')
        this.mediaCookiesSpinners = document.querySelectorAll('.media-cookies-spinner')
        this.generatedIds = []
        this.assignUniqueIds()

        if (typeof cookieConsent === 'undefined' || !cookieConsent('media')) {
            this.showMediaCookiesPrompts()
            this.hideMediaCookiesSpinners()
        }

        Emitter.on('onExternalScriptsLoaded', () => {
            this.reloadTwitterScript()
            this.handleMediaEmbeds()
            this.handleAnalytics()
        })
        Emitter.on('onInjectedElementLoaded', dataElementId => {
            this.showMediaEmbed(dataElementId)
        })
        Emitter.on('onMediaCookiesAccepted', () => {
            this.showMediaCookiesSpinners()
            this.generateScripts(this.externalScripts)
        })
        Emitter.on('onMediaCookiesRejected', () => {
            this.showMediaCookiesPrompts()
            this.removeMediaEmbeds()
            this.removeAutoplay()
        })

        this.submit = document.getElementById('wtm_cookie_bar_submit')
        if (this.submit) {
            this.submit.addEventListener('click', this.onSubmit)
        }

        this.manageButton = document.getElementById('wtm_cookie_bar_manage')
        if (this.manageButton) {
            this.manageButton.addEventListener('click', this.onManageButtonClick)
        }

        this.acceptAllButton = document.getElementById('wtm_cookie_bar_accept_all')
        if (this.acceptAllButton) {
            this.acceptAllButton.addEventListener('click', this.onAcceptAllClick)
        }

        this.openButton = document.getElementById('wtm_cookie_bar_open_button')
        if (this.openButton) {
            this.openButton.addEventListener('click', this.onOpenButtonClick)
        }

        this.mediaEnable = document.querySelectorAll('.media-cookies-button-enable')
        this.mediaEnable.forEach(button => button.addEventListener('click', this.showMediaCookiesOverlay))

        this.cookieBar = document.getElementById('wtm_cookie_bar')
        this.analyticsCheckbox = document.getElementById('id_analytics')
        this.mediaCheckbox = document.getElementById('id_media')
        this.thumbnailLinkText = document.getElementById('thumbnail-link-text')
        this.templateContainers = document.querySelectorAll('.embed-template-container')

        this.declarationContainerOverlay = document.getElementById('declaration-container-outer')
        this.scriptsLoadedCount = 0

        this.declarationContainer = document.getElementById('declaration-container')
        if (this.declarationContainer) {
            this.declarationContainer.addEventListener('click', (e) => {
                e.stopPropagation()
            })
        }
        this.mediaCookiesOverlay = document.getElementById('media-cookies-overlay')
        if (this.mediaCookiesOverlay) {
            this.mediaCookiesOverlay.addEventListener('click', this.closeCookiesBar)
        }

        this.videoThumbnails = document.querySelectorAll('.youtube-player,.vimeo-player')
        this.videoThumbnails.forEach(el => el.addEventListener('click', this.onThumbnailClick))

        if (this.invalidCookies() || !this.areCookiesSet()) {
            this.mediaCheckbox.checked = true
            this.analyticsCheckbox.checked = true
            this.showCookiesBar()
        }
        if (typeof cookieConsent !== 'undefined' && cookieConsent('media')) {
            this.generateScripts(this.externalScripts)
        }
    }

    assignUniqueIds = () => {
        document.querySelectorAll('.generate-element-id').forEach(el => el.dataset.elementId = this.generateUniqueId())
    }

    elementOnLoad = (element) => {
        const dataElementId = this.findDataElementId(element)
        if (dataElementId) {
            Emitter.emit('onInjectedElementLoaded', dataElementId)
        }
    }
    reloadTwitterScript = () => {
        if (typeof window.twttr !== 'undefined' && typeof window.twttr.widgets !== 'undefined') {
            window.twttr.widgets.load()
        }
    }

    injectTypeForm = () => {
        document.querySelectorAll('.typeform_form').forEach((form) => {
            const typeformUrl = form.dataset.typeformUrl
            const typeformId = typeformUrl.split('/').slice(-1)[0]
            const opacity = 100 - form.dataset.typeformTransparency
            const hideHeaders = form.dataset.typeformHideHeaders === 'true'
            const hideFooter = form.dataset.typeformHideFooter === 'true'
            const options = {
                container: document.querySelector(`.typeform_form[data-typeform-url="${typeformUrl}"]`),
                opacity: opacity,
                hideHeaders: hideHeaders,
                hideFooter: hideFooter,
                iframeProps: {
                    style: `width: 100%; height: ${form.offsetHeight}px;`,
                },
            }
            createWidget(typeformId, options)
        })
    }

    findDataElementId = (node) => {
        if (!node) {
            return null
        }
        if (node.dataset && node.dataset.elementId) {
            return node.dataset.elementId
        }
        return this.findDataElementId(node.parentElement)
    }

    generateID = () => {
        // 7! = 5040 possible ids, should be enough and it's easier to debug when it's shorter
        return [...('ABCDEFG')].sort(() => Math.random() - .5).join('')
    }

    generateUniqueId = () => {
        let uniqueID = this.generateID()
        while (this.generatedIds.includes(uniqueID)) {
            uniqueID = this.generateID()
        }
        this.generatedIds.push(uniqueID)

        return uniqueID
    }

    invalidCookies = () => {
        const wtmCookie = Cookies.get('wtm')
        return !wtmCookie || !wtmCookie.includes('necessary') || !wtmCookie.includes('media') || !wtmCookie.includes('analytics')
    }

    onSubmit = (e) => {
        this.setCookies(this.mediaCheckbox.checked, this.analyticsCheckbox.checked)
        if (e.currentTarget.dataset.playable) {
            this.playVideo(e.currentTarget.dataset.elementId)
        }
        this.closeDeclarationContainerOverlay()
        this.closeCookiesBar()
    }

    onManageButtonClick = () => {
        this.showDeclarationContainerOverlay()
        if (typeof cookieConsent !== 'undefined' && cookieConsent('analytics')) {
            this.analyticsCheckbox.checked = true
        }
        if (typeof cookieConsent !== 'undefined' && cookieConsent('media')) {
            this.mediaCheckbox.checked = true
        }
    }

    onAcceptAllClick = (e) => {
        this.setCookies()
        if (e.currentTarget.dataset.playable) {
            this.playVideo(e.currentTarget.dataset.elementId)
        }
        this.closeCookiesBar()
    }

    playVideo = (elementId) => {
        const el = document.querySelector(`.youtube-player[data-element-id="${elementId}"], .vimeo-player[data-element-id="${elementId}"]`)
        if (el) {
            el.click()
        }
    }

    onOpenButtonClick = () => {
        this.showCookiesBar()
    }

    onThumbnailClick = (e) => {
        if (typeof cookieConsent === 'undefined' || cookieConsent('media')) {
            return
        }
        const videoUrl = e.currentTarget.dataset.url
        this.showThumbnailLinkText(videoUrl)
        const elementId = e.currentTarget.dataset.elementId
        this.setAutoplay(elementId)
        this.showMediaCookiesOverlay()
    }

    setAutoplay = (elementId) => {
        this.acceptAllButton.dataset.elementId = this.submit.dataset.elementId = elementId
        this.acceptAllButton.dataset.playable = this.submit.dataset.playable = 'true'
    }

    removeAutoplay = () => {
        this.acceptAllButton.dataset.elementId = this.submit.dataset.elementId = ''
        this.acceptAllButton.dataset.playable = this.submit.dataset.playable = ''
    }

    setCookies = (media = true, analytics = true) => {
        let params = {
            'necessary': true, 'media': media, 'analytics': analytics, 'set': true,
        }

        let cookiesArray = []
        for (const key in params) {
            cookiesArray.push(`${key}:${!!params[key]}`)
        }
        const cookiesString = cookiesArray.join('|')
        Cookies.set('wtm', cookiesString, {expires: 365})

        if (typeof cookieConsent !== 'undefined' && cookieConsent('media')) {
            Emitter.emit('onMediaCookiesAccepted')
        } else {
            Emitter.emit('onMediaCookiesRejected')
        }

        this.handleAnalytics()
    }

    handleMediaEmbeds = () => {
        if (typeof cookieConsent !== 'undefined' && cookieConsent('media')) {
            this.insertMediaEmbeds()
            this.hideMediaCookiesPrompts()
        } else {
            this.showMediaCookiesPrompts()
            this.removeMediaEmbeds()
        }
    }

    generateScripts = (URLS) => {
        if (this.areExternalScriptsLoaded()) {
            Emitter.emit('onExternalScriptsLoaded')
            return
        }
        URLS.forEach((URL) => {
            this.generateScript(URL, this.onScriptLoaded.bind(this))
        })
    }

    areExternalScriptsLoaded = () => {
        return this.scriptsLoadedCount === this.externalScripts.length
    }

    onScriptLoaded = () => {
        this.scriptsLoadedCount++
        if (this.areExternalScriptsLoaded()) {
            Emitter.emit('onExternalScriptsLoaded')
        }
    }

    handleAnalytics = () => {
        if (typeof cookieConsent !== 'undefined' && cookieConsent('analytics')) {
            this.insertAnalytics()
        } else {
            this.removeAnalytics()
        }
    }

    removeAnalytics = () => {
        this.denyGAConsent()
        this.removeCookies()
        this.removeHubspot()
    }

    insertMediaEmbeds() {
        this.templateContainers.forEach((container) => {
            if (container.classList.contains('injected')) {
                return
            }
            const template = container.querySelector('template')
            container.appendChild(template.content.cloneNode(true))
            container.classList.add('injected')
        })
        this.injectTypeForm()
        this.checkIframesLoaded()
    }

    generateScript(URL, callback) {
        const tag = document.createElement('script')
        tag.onload = callback
        tag.onerror = function () {
            Bugsnag.notify(new Error(`Script load error: ${URL}`))
            callback()
        }
        tag.src = URL

        const firstScriptTag = document.getElementsByTagName('script')[0]
        firstScriptTag.parentNode.insertBefore(tag, firstScriptTag)
    }

    removeMediaEmbeds() {
        this.templateContainers.forEach((container) => {
            container.classList.add('invisible')
            container.classList.remove('injected')
            // delete all contents but the template tag
            const children = [...container.childNodes]
            children.forEach(child => {
                if (child.nodeName !== 'TEMPLATE') {
                    container.removeChild(child)
                }
            })
        })
    }

    insertAnalytics() {
        if (typeof window.gtag === 'undefined') {
            initializeGA()
        } else {
            this.grantGAConsent()
        }
        this.addHubspot()
    }

    areCookiesSet = () => {
        return Cookies.get('wtm').includes('set:true') || Cookies.get('wtm_id')
    }

    removeCookies = () => {
        for (const key in Cookies.get()) {
            if (!this.cookieWhitelist.includes(key)) {
                Cookies.remove(key)
            }
        }
    }

    grantGAConsent = () => {
        if (typeof window.gtag !== 'undefined') {
            window.gtag('consent', 'update', {
                'analytics_storage': 'granted',
            })
        }
    }

    denyGAConsent = () => {
        if (typeof window.gtag !== 'undefined') {
            window.gtag('consent', 'update', {
                'analytics_storage': 'denied',
            })
        }
    }

    showMediaCookiesOverlay = () => {
        this.showCookiesBar()
        const cookieBarHeight = this.cookieBar.offsetHeight
        this.cookieBar.style.bottom = `calc(50% - ${cookieBarHeight / 2}px)`
        this.mediaCookiesOverlay.style.display = 'block'
        document.body.style.overflow = 'hidden'
    }

    closeMediaCookiesOverlay = () => {
        this.removeAutoplay()
        this.mediaCookiesOverlay.style.display = 'none'
        document.body.style.overflow = 'unset'
        document.body.style.overflowX = 'hidden'
    }

    showDeclarationContainerOverlay = () => {
        this.declarationContainerOverlay.classList.remove('hidden')
    }

    closeDeclarationContainerOverlay = () => {
        this.declarationContainerOverlay.classList.add('hidden')
    }

    showCookiesBar = () => {
        this.cookieBar.classList.remove('hidden')
    }
    closeCookiesBar = () => {
        this.hideThumbnailLinkText()
        this.cookieBar.classList.add('hidden')
        this.cookieBar.style.bottom = '0'
        this.closeMediaCookiesOverlay()
    }

    showThumbnailLinkText = (videoUrl) => {
        const thumbnailVideoLink = document.getElementById('thumbnail-video-link')
        thumbnailVideoLink.href = videoUrl
        thumbnailVideoLink.textContent = videoUrl
        this.thumbnailLinkText.classList.remove('hidden')
    }

    hideThumbnailLinkText = () => {
        this.thumbnailLinkText.classList.add('hidden')
    }

    addHubspot() {
        if (typeof cookieConsent !== 'undefined' && cookieConsent('analytics')) {
            const script = document.createElement('script')
            script.type = 'text/javascript'
            script.id = 'hs-script-loader'
            script.async = true
            script.defer = true
            script.src = '//js.hs-scripts.com/19565345.js'
            document.body.appendChild(script)
        }
    }

    removeHubspot() {
        const script = document.getElementById('hs-script-loader')
        if (script) {
            script.remove()
        }
    }

    showMediaCookiesPrompts() {
        this.mediaCookiesPrompts.forEach((el) => el.classList.remove('hidden'))
    }

    hideMediaCookiesPrompts() {
        this.mediaCookiesPrompts.forEach((el) => el.classList.add('hidden'))
    }

    showMediaCookiesSpinners() {
        this.mediaCookiesSpinners.forEach((el) => el.classList.remove('hidden'))
    }

    hideMediaCookiesSpinners() {
        this.mediaCookiesSpinners.forEach((el) => el.classList.add('hidden'))
    }

    showMediaEmbed(dataElementId) {
        let container = document.querySelector(`.generate-element-id[data-element-id="${dataElementId}"]`)
        if (container) {
            container.classList.remove('invisible')
            const spinner = container.parentElement.querySelector('.media-cookies-spinner')
            if (spinner) {
                spinner.classList.add('hidden')
            }
        }
    }

    checkIframesLoaded() {
        this.templateContainers.forEach((container) => {
            let interval = setInterval(() => {
                let rendered = container.querySelector('iframe')
                if (rendered && rendered.offsetHeight > 10) {
                    clearInterval(interval)
                    this.elementOnLoad(rendered)
                }
            }, 200)
        })
    }

}

export const CookieControlComponent = {
    name: 'CookieControl', componentClass: CLASSES.COMPONENT, Source: CookieControl,
}
