import { GLOBAL_CONSTANTS } from 'utils/constants'
import chunkArray from 'utils/chunkArray'

const STRINGS = {
    ALL: 'all',
    FEATURED: 'featured',
}

const SELECTORS = {
    COMPONENT: '.js-company-filters',
    FORM: '.js-company-form',
    FORM_SELECTS: '.js-company-form-select',
    COMPANY: '.js-company',
    NOTICE: '.js-company-filters-notice',
    SEED_CONTENT: '.js-company-seed-content',
}

export default class CompanyFilters {
    /**
     * @desc Populates drop-down filters with options and filters page content accordingly
     * @param {HTMLElement} filtersElement - Element for the filters container
     */

    constructor(element) {
        this.element = element

        this.form = this.element.querySelector(SELECTORS.FORM)
        this.formSelects = this.element.querySelectorAll(SELECTORS.FORM_SELECTS)
        this.notice = this.element.querySelector(SELECTORS.NOTICE)

        this.companies = this.element.querySelectorAll(SELECTORS.COMPANY)

        this.seedContent = this.element.querySelectorAll(SELECTORS.SEED_CONTENT)

        this.updateFilters = this.updateFilters.bind(this)
        this.setHistoryState = this.setHistoryState.bind(this)

        this.companyMapping = this.generateCompanyFilterMapping()
        this.filters = this.setFilterState()

        this.initialize()
    }

    /**
     * @desc initialize the class functions after global variables are defined
     */
    initialize() {
        this.registerListeners()
        this.filterResults()

        if (this.params.length) {
            const padding = parseInt(window.getComputedStyle(this.element).marginTop || this.element.currentStyle.marginTop)

            window.scrollTo({
                top: this.element.offsetTop - padding,
                behavior: 'smooth',
            })
        }
    }

    registerListeners() {
        this.form.addEventListener('change', this.updateFilters)

        this.element.addEventListener('click', function(e) {
            const url = e.target.href
            if (url && url.match(/\/companies\/(backed\/.*)?$/)) {
                e.preventDefault()
                window.history.pushState(null, '', url)
                this.setHistoryState()
            }
        }.bind(this))

        window.onpopstate = this.setHistoryState
        window.onpushstate = this.setHistoryState
    }

    setHistoryState() {
        this.filters = this.setFilterState()
        this.filterResults()
    }

    /**
     * Update our query params on filtering and navigation
     */
    updateQueryString() {
        const url = new URL(document.location)
        const routes = [url.pathname.split('/')[1]]

        if (this.filters.backed === 'all') {
            Object.keys(this.filters).forEach((key) => {
                if (key !== 'backed') {
                    routes.push(key, this.filters[key])
                }
            })
        } else {
            routes.push('backed', this.filters.backed)
        }

        url.pathname = routes.join('/')
        window.history.pushState(null, '', url.toString())
    }

    /**
     * Dynamically set our filter state off of queried selects
     */
    setFilterState() {
        const filters = {}
        const filterMap = new Map()

        const url = new URL(document.location)

        /**
         * URL = /route/param/value/param/value
         * Splitting the above generates ["", route, param, value, param, value]
         * Slicing at 2, -1 starts us after the route and ends at the end of the array
         */
        this.params = url.pathname.split('/').slice(2, -1)
        const stateMapping = [...chunkArray(this.params, 2)]

        stateMapping.forEach((filter) => {
            filterMap.set(filter[0], filter[1])
        })

        if (!filterMap.size) {
            filterMap.set('backed', 'select')
        }

        this.formSelects.forEach((select) => {
            select.value = filterMap.get(select.name) || STRINGS.ALL
            filters[select.name] = select.value
        })

        return filters
    }

    generateCompanyFilterMapping() {
        return Array.from(this.companies).map((company) => {
            const backed = JSON.parse(company.dataset.backed)
            const regions = JSON.parse(company.dataset.regions)
            const sectors = JSON.parse(company.dataset.sectors)
            return {
                backed,
                regions,
                sectors,
                filterList: [...backed, ...regions, ...sectors],
                el: company,
            }
        })
    }

    /**
     * Update our filter state on form submit.
     */
    updateFilters(e) {
        e.preventDefault()

        const formData = new FormData(this.form)
        const filterObj = {}

        for (const field of formData.entries()) {
            // eslint-disable-line no-undef
            filterObj[field[0]] = field[1]
        }

        this.filters = Object.assign(this.filters, filterObj)
        this.filterResults()
        this.updateQueryString()
    }

    filterResults() {
        let activeCount = 0

        this.element.classList.toggle(STRINGS.FEATURED, !this.filters.backed.includes(STRINGS.ALL))

        this.seedContent.forEach((content) => {
            content.classList.toggle(GLOBAL_CONSTANTS.CLASSES.HIDDEN, !content.dataset.backed.split(',').includes(this.filters.backed))
        })

        this.companyMapping.forEach((company) => {
            const activeMatches = Object.keys(this.filters).map((key) => {
                const filter = this.filters[key]
                return (
                    filter === STRINGS.ALL ||
                    company.filterList.includes(filter)
                )
            })

            if (activeMatches.includes(false)) {
                company.el.classList.add(GLOBAL_CONSTANTS.CLASSES.HIDDEN)
            } else {
                company.el.classList.remove(GLOBAL_CONSTANTS.CLASSES.HIDDEN)
                activeCount++
            }
        })

        if (activeCount === 0) {
            this.notice.classList.add(GLOBAL_CONSTANTS.CLASSES.VISIBLE)
        } else {
            this.notice.classList.remove(GLOBAL_CONSTANTS.CLASSES.VISIBLE)
        }
    }

    /**
     * @desc Tear down the event listeners
     */
    tearDown() {
        this.form.removeEventListener('submit', this.onFormSubmit)
    }
}

/**
 * @desc Component definition for the CompanyFilters module
 */

export const CompanyFiltersComponent = {
    name: 'Company Filters',
    componentClass: SELECTORS.COMPONENT,
    Source: CompanyFilters,
}
